import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { FunctionComponent, useRef, useState } from "react";
import { CustomButton } from "../../../../components/CustomButton";
import { putCall } from "../../../../services/api_service";
import { Toast } from "primereact/toast";

interface ViewElementModelProps {
  selectedViewElement: any;
  setSelectedViewElement: Function;
}

const ViewElementModel: FunctionComponent<ViewElementModelProps> = ({
  selectedViewElement,
  setSelectedViewElement,
}) => {
  const [isEditMode, setIsEditMode] = useState(true);
  const toast = useRef<any>(null);
  // const [objectToBeUpdated, setObjectToBeUpdated] = useState(selectedViewElement);
  const risk = [
    { label: "Unrated", value: "Unrated" },
    { label: "Low Risk", value: "Low Risk" },
    { label: "Moderate Risk", value: "Moderate Risk" },
    { label: "High Risk", value: "High Risk" },
    { label: "Extreme Risk", value: "Extreme Risk" },
  ];
  const currentStatuses = [
    { label: "Well Managed", value: "Well Managed" },
    { label: "Satisfactory", value: "Satisfactory" },
    {
      label: "Some Improvement Opportunity",
      value: "Some Improvement Opportunity",
    },
    {
      label: "Major Improvement Opportunity",
      value: "Major Improvement Opportunity",
    },
    {
      label: "Unacceptable Risk Exposure",
      value: "Unacceptable Risk Exposure",
    },
  ];

  const onValueChange = (event: any) => {
    const { name, value } = event.target;
    setSelectedViewElement({
      ...selectedViewElement,
      [name]: value,
    });
  };

  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <Toast ref={toast} />
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setSelectedViewElement(null);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Element Details</p>
            </div>
            <br />
            {selectedViewElement.name}
            <br /> <br />
            <div className="columns">
              <div className="column">
                <strong> Risk:</strong>
                {isEditMode ? (
                  <Dropdown
                    name="risk"
                    value={selectedViewElement.risk}
                    options={risk}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(event) => onValueChange(event)}
                    placeholder="Risk"
                    itemTemplate={(option) => {
                      return (
                        <span
                          className={`product-badge status-${option.value.toLowerCase()}`}
                        >
                          {option.label}
                        </span>
                      );
                    }}
                  />
                ) : (
                  selectedViewElement.risk
                )}
              </div>
              <div className="column">
                <strong> Status:</strong>{" "}
                {isEditMode ? (
                  <Dropdown
                    name="status"
                    value={selectedViewElement.status}
                    options={currentStatuses}
                    optionLabel="label"
                    optionValue="value"
                    onChange={(event) => onValueChange(event)}
                    style={{ width: "100%" }}
                    placeholder="Status"
                    itemTemplate={(option) => {
                      return (
                        <span
                          className={`product-badge status-${option.value.toLowerCase()}`}
                        >
                          {option.label}
                        </span>
                      );
                    }}
                  />
                ) : (
                  selectedViewElement.status
                )}
              </div>
            </div>
            <div
              className="columns"
              style={{ width: "100%", flexDirection: "column" }}
            >
              <div>
                <div className="column">
                  <strong>
                    {" "}
                    <span style={{ color: "red" }}>*</span>Comment:
                  </strong>{" "}
                  {isEditMode ? (
                    <InputText
                      type="text"
                      name="comments"
                      onChange={(event) => onValueChange(event)}
                      value={selectedViewElement.comments}
                      className="p-inputtext-sm"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    selectedViewElement.comments
                  )}
                </div>
                <div className="column">
                  <strong> Assessment Remarks:</strong>
                  {isEditMode ? (
                    <InputText
                      type="text"
                      name="remarks"
                      onChange={(event) => onValueChange(event)}
                      value={selectedViewElement.remarks}
                      className="p-inputtext-sm"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    selectedViewElement.remarks
                  )}
                </div>
                <div className="column">
                  <strong>Next Followup:</strong>
                  {isEditMode ? (
                    <InputText
                      type="date"
                      name="followup"
                      value={selectedViewElement.followup}
                      className="p-inputtext-sm"
                      onChange={(event) => onValueChange(event)}
                      style={{ width: "100%" }}
                    />
                  ) : (
                    new Date(selectedViewElement.followup).toLocaleDateString(
                      "en-US"
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="has-text-centered">
              {isEditMode ? (
                <>
                  <CustomButton
                    lable="Update"
                    onClick={() => {
                      if (
                        !selectedViewElement.comments ||
                        !selectedViewElement.comments.length
                      ) {
                        alert("Comment Required");
                        return;
                      }
                      putCall("/entity-element", {
                        ...selectedViewElement,
                        auditStatus: selectedViewElement.auditstatus,
                      }).then((data) => {
                        toast.current.show({
                          severity: "success",
                          summary: "",
                          detail: "Updated Successfully",
                          life: 3000,
                        });
                        setIsEditMode(false);
                      });
                    }}
                  ></CustomButton>
                  &nbsp;
                  <CustomButton
                    lable="Cancel"
                    isSecoundry={true}
                    onClick={() => {
                      setIsEditMode(false);
                    }}
                  ></CustomButton>
                </>
              ) : (
                <>
                  <CustomButton
                    lable="Ok"
                    onClick={() => {
                      setSelectedViewElement(null);
                    }}
                  ></CustomButton>
                  &nbsp;
                  <CustomButton
                    isSecoundry={true}
                    lable="Edit"
                    onClick={() => {
                      setIsEditMode(true);
                    }}
                  ></CustomButton>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ViewElementModel;
