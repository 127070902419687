import { FunctionComponent, useState } from "react";
import { postCall } from "../../../../services/api_service";
import {
  CustomButton,
  CustomOutlineButton,
} from "../../../../components/CustomButton";
import CutomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";

interface CreateSubModuleModelProps {
  showModel: boolean;
  setShowModel: Function;
  getModule: Function;
  modules: any;
}

const CreateSubModuleModel: FunctionComponent<CreateSubModuleModelProps> = ({
  showModel,
  setShowModel,
  getModule,
  modules,
}) => {
  const [name, setname] = useState("");
  const [selectedModule, setSelectedModule] = useState<any>({});

  const addModule = () => {
    if (name !== "") {
      postCall("/module/submodule/" + selectedModule.id, {
        name,
      }).then(() => {
        setShowModel(false);
        setname("");
        setSelectedModule({});
        getModule();
      });
    }
  };

  return (
    <div className={"modal " + (showModel ? "is-active" : "")}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <section className="modal-card-body">
          <div className=" mb-3">
            <button
              className="delete"
              style={{ float: "right" }}
              aria-label="close"
              onClick={() => {
                setShowModel(false);
              }}
            ></button>

            <p className="title has-text-centered">Add Sub-Module</p>
          </div>
          <div>
            <CustomSelect
              handleOpChange={(value: any) => {
                setSelectedModule(value);
              }}
              opList={modules}
              placeholder="Select Module"
              selectedOp={selectedModule}
            ></CustomSelect>
          </div>
          <div className="p-4">
            <CutomInput
              label="Sub-Module Name"
              value={name}
              onChange={($event) => {
                setname($event.target.value);
              }}
            ></CutomInput>
          </div>
          <div className="has-text-centered mt-3">
            <CustomButton lable="Create" onClick={addModule}></CustomButton>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CreateSubModuleModel;
