import { FunctionComponent } from "react";
import HeatMap from "./components/HeatMap_";

interface DashboadPageProps {}

const DashboadPage: FunctionComponent<DashboadPageProps> = () => {
  return <HeatMap></HeatMap>;
};

export default DashboadPage;
