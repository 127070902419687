import { FunctionComponent, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { CustomIconButton } from "../../../../components/CustomButton";

interface UsersTableProps {
  data: any;
  setselectedEditUser: Function;
  deleteUser: Function;
}

const EntityTable: FunctionComponent<UsersTableProps> = ({
  data,
  setselectedEditUser,
  deleteUser,
}) => {
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onCustomPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const template: any = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="p-mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <div style={{ width: "80px" }}>
            <Dropdown
              value={options.value}
              options={dropdownOptions}
              onChange={options.onChange}
              appendTo={document.body}
            />
          </div>
        </>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <CustomIconButton
          onClick={() => {
            setselectedEditUser(rowData);
          }}
          icon="edit.svg"
        ></CustomIconButton>
        <CustomIconButton
          onClick={() => {
            deleteUser(rowData.id);
          }}
          icon="delete.svg"
        ></CustomIconButton>
      </div>
      // <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" onClick={() => setselectedEditPs(rowData)} />
    );
  };

  return (
    <div style={{ width: "100%" }} className="">
      <DataTable
        value={data}
        paginator
        paginatorTemplate={template}
        first={first}
        rows={rows}
        onPage={onCustomPage}
        style={{ width: "100%" }}
        paginatorClassName="p-jc-end"
        className="p-mt-6"
      >
        <Column field="index" style={{ width: "5rem" }} header="Sr No"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="description" header="Description"></Column>
        <Column field="type" style={{ width: "5rem" }} header="Type"></Column>
        <Column
          field="address"
          style={{ width: "5rem" }}
          header="Address"
        ></Column>
        <Column
          field="modules"
          style={{ textAlign: "center", width: "10rem" }}
          header="Assigned Modules"
        ></Column>

        <Column
          body={actionBodyTemplate}
          style={{ textAlign: "center", width: "12em" }}
        ></Column>

        {/* {header.map(head => <Column key={head} field={head} header={head}></Column>)} */}
      </DataTable>{" "}
      <br />
    </div>
  );
};

export default EntityTable;
