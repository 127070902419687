import { FunctionComponent, useEffect, useState } from "react";
import { CustomButton } from "../../../../components/CustomButton";
import CutomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import { getCall, postCall, putCall } from "../../../../services/api_service";
import { roleList } from "../../../../utils/utils";

interface EditUserProps {
  getUsers: Function;
  selectedEditUser: any;
  setselectedEditUser: Function;
}

const EditUser: FunctionComponent<EditUserProps> = ({
  selectedEditUser,
  getUsers,
  setselectedEditUser,
}) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [role, setrole] = useState<any>("");
  const [entity, setEntity] = useState<any>({});
  const [unit, setUnit] = useState<any>({});

  const [entityList, setEntityList] = useState([]);
  const [unitList, setUnitsList] = useState([]);

  useEffect(() => {
    console.log("selec", selectedEditUser);

    setname(selectedEditUser.name);
    setemail(selectedEditUser.email);
    setrole({ name: selectedEditUser.role, id: selectedEditUser.role });
    setEntity({
      name: selectedEditUser.entity_name,
      id: selectedEditUser.entity_id,
    });
    setUnit({ name: selectedEditUser.unit_name, id: selectedEditUser.unit_id });
  }, [selectedEditUser]);

  const getEntities = () => {
    getCall("/users/entities").then((data) => {
      data = data.map((el: any, index: number) => {
        return { name: el.name, id: el.id };
      });
      setEntityList(data);
    });
  };
  const getUnits = (entity_id: string) => {
    getCall("/users/units/" + entity_id).then((data) => {
      data = data.map((el: any, index: number) => {
        return { name: el.name, id: el.id };
      });
      setUnitsList(data);
    });
  };

  const updateUser = () => {
    console.log("unit", unit);

    if (name !== "") {
      putCall("/users/" + selectedEditUser.id, {
        name,
        useremail: email,
        role: role.id,
        unit_id: unit.id,
        entity_id: entity.id,
      }).then(() => {
        getUsers();
        setselectedEditUser(null);
      });
    }
  };

  useEffect(() => {
    getEntities();
  }, []);

  useEffect(() => {
    console.log("entit", entity);

    if (entity && entity.id) {
      getUnits(entity.id);
    }
  }, [entity]);

  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setselectedEditUser(null);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Edit Users</p>
            </div>
            <br />
            <div className="columns is-multiline">
              <div className="column is-6">
                <CutomInput
                  label="Name"
                  onChange={($event) => {
                    setname($event.target.value);
                  }}
                  value={name}
                ></CutomInput>
              </div>

              <div className="column is-6">
                <CutomInput
                  label="Email"
                  onChange={($event) => {
                    setemail($event.target.value);
                  }}
                  value={email}
                ></CutomInput>
              </div>
              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    setrole(value);
                  }}
                  opList={roleList}
                  placeholder="Select Role"
                  selectedOp={role}
                ></CustomSelect>
              </div>
              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    setEntity(value);
                  }}
                  opList={entityList}
                  placeholder="Select Entity"
                  selectedOp={entity}
                ></CustomSelect>
              </div>
              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    setUnit(value);
                  }}
                  opList={unitList}
                  placeholder="Select Unit"
                  selectedOp={unit}
                ></CustomSelect>
              </div>
            </div>
            <div className="has-text-centered">
              <CustomButton lable="save" onClick={updateUser}></CustomButton>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditUser;
