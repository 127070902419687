import { FunctionComponent, useState } from "react";
import { postCall } from "../../../../services/api_service";
import { CustomButton, CustomOutlineButton } from "../../../../components/CustomButton";
import CutomInput from "../../../../components/CustomInput";

interface CreateModuleModelProps {
    showModel: boolean,
    setShowModel: Function,
    getModule: Function
}

const CreateModuleModel: FunctionComponent<CreateModuleModelProps> = ({ showModel, setShowModel, getModule }) => {

    const [name, setname] = useState('');
    const [subModule, setsubModule] = useState<Array<any>>([]);

    const addModule = () => {
        if (name !== '') {
            postCall('/module', { name, subModule }).then(() => {
                setShowModel(false);
                getModule();
            });
        }
    }

    const addSubmodule = () => {
        let tempSubModule = subModule;
        tempSubModule.push({ name: '' });
        setsubModule([...tempSubModule]);
    }

    const updateSubModule = (i: number, text: string) => {
        let tempSubModule = subModule;
        tempSubModule[i].name = text;
        setsubModule([...tempSubModule]);
    }


    return (
        <div className={"modal " + (showModel ? 'is-active' : '')}>
            <div className="modal-background"></div>
            <div className="modal-card">

                <section className="modal-card-body">
                    <div className=" mb-3" >
                        <button className="delete" style={{ float: "right" }} aria-label="close" onClick={() => { setShowModel(false) }} ></button>

                        <p className="title has-text-centered">Add Module</p>

                    </div>
                    <div className="p-4">
                        <CutomInput label="Module Name" onChange={($event) => { setname($event.target.value) }} ></CutomInput>

                        {/* <TextField id="outlined-basic" value={name} onChange={($event) => { setname($event.target.value) }} style={{ width: '100%' }} label="Module Name" variant="outlined" /> */}

                        {subModule.length > 0 ? <p className="has-text-weight-semibold mt-4">sub-Module name</p> : ''}

                        {subModule.map(
                            (sm, i) => <div className="pl-3" >
                                <CutomInput label="sub-Module name" onChange={($event) => { updateSubModule(i, $event.target.value) }} ></CutomInput>
                                {/* <TextField id="outlined-basic" value={sm.name} onChange={($event) => { updateSubModule(i, $event.target.value) }} style={{ width: '100%' }} label="Module Name" variant="outlined" /> */}
                            </div>
                        )}
                    </div>
                    <div className="has-text-centered" >
                        <CustomOutlineButton lable="Add Sub Module" onClick={addSubmodule} ></CustomOutlineButton>
                    </div>
                    <div className="has-text-centered mt-3">
                        <CustomButton lable='Create' onClick={addModule}></CustomButton>
                    </div>

                </section>

            </div>
        </div>
    );
}

export default CreateModuleModel;