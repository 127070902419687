import { Row, Col, Button } from "antd";
import React, { useState, useEffect } from "react";
import FilterSelect from "../../../../components/FilterSelect";
import MoreOptions from "../../../../components/MoreOptions";
import { getCall, postCall } from "../../../../services/api_service";
import EditDir from "../components/actions/editDir";
import AddDir from "../components/addDirectory";
import DirectoryTable from "../components/DirectoryTable";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { PRODUCT, GENERAL } from "../folder-section";

const SubDirectory = () => {
  const [dataSource, setdataSource] = useState([]);
  const [selected, setSelected] = useState(null);
  const [type, setType] = useState(null);

  const [modalToShow, setModalToShow] = React.useState("");
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const parentDirectory = id;

  const setShowModel = () => {
    setModalToShow("");
  };

  useEffect(() => {
    // getGroup();

    console.log("parent", parentDirectory);
    if (parentDirectory && parentDirectory != "" && type) {
      getDir();
    }
  }, [parentDirectory, type]);

  useEffect(() => {
    const path =
      location.pathname.split("/")[1] == "product-standard" ? PRODUCT : GENERAL;
    setType(path);
    console.log("pathsss", location.pathname.split("/"));
  }, [location.pathname]);

  const getDir = () => {
    getCall(`/directory/${type}/${parentDirectory}`).then((res) => {
      const originData_ = res.map((row, index) => {
        return {
          index: index + 1,
          ...row,
          actions: (
            <MoreOptions
              actionsList={[
                {
                  label: "Edit Sub Directory",
                  onClick: () => {
                    setModalToShow("Edit Directory");
                    setSelected({ ...row });
                  },
                },
                {
                  label: "Delete Sub Directory",
                  onClick: () => {
                    deleteDir(row.id);
                  },
                },
              ]}
            />
          ),
        };
      });
      setdataSource(originData_);
      // setFilterData(originData_);
    });
  };
  const deleteDir = (id) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      postCall("/directory/sub-directory/delete", { id }).then(() => {
        getDir();
      });
    }
  };
  return (
    <div className="section container is-fluid">
      <Row gutter={[20, 20]}>
        <Col>
          <div>{}</div>
        </Col>
        <Col span={8}>
          <FilterSelect
            placeholder={
              type == PRODUCT
                ? "Search Product Standard Sub Folders"
                : "Search Sub Directory"
            }
            options={dataSource}
            label="name"
            value="id"
            onChange={(el) => {
              history.push(`/sub-directory/${el}`);
            }}
          />
        </Col>
        <Col span={13}></Col>
        <Col>
          <Button
            type="secoundry"
            onClick={() => setModalToShow("Add Directory")}
          >
            Add
          </Button>
        </Col>
      </Row>
      <br />

      <DirectoryTable
        dataSource={dataSource}
        parentDirectory={parentDirectory}
      />

      <AddDir
        getDir={getDir}
        showModel={modalToShow === "Add Directory"}
        setShowModel={setShowModel}
        title={
          type == PRODUCT ? "Add Product Standard Sub Folder" : "Add Sub Folder"
        }
        parentDirectory={parentDirectory}
        type={type}
      />

      <EditDir
        getDir={getDir}
        selected={selected}
        showModel={modalToShow === "Edit Directory"}
        setShowModel={setShowModel}
        title={
          type == PRODUCT
            ? "Edit Product Standard Sub Folder"
            : "Edit Sub Folder"
        }
        parentDirectory={parentDirectory}
      />
    </div>
  );
};

export default SubDirectory;
