import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { CustomIconButton } from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";
import {
  deleteCall,
  getCall,
  postCall,
} from "../../../../services/api_service";
import EditElementModel from "../../elements_page/components/EditElement";
import ViewElementModel from "../../elements_page/components/ViewElementDetail";
import "../heatmap.css";
import Stat from "./Stat";

interface HeatMapProps {}

const HeatMap: FunctionComponent<HeatMapProps> = () => {
  const [moduleList, setmoduleList] = useState<any>([]);
  const [subModuleList, setsubModuleList] = useState<any>([]);

  const [selectedModule, setSelectedModule] = useState("");
  const [selectedSubModule, setSelectedSubModule] = useState("");
  const [hmData, sethmData] = useState<any>(hmData_);
  const [selectedCellDetail, setSelectedCellDetail] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState("");
  const [selectedViewElement, setSelectedViewElement] = useState(null);
  const [selectedEditElement, setselectedEditElement] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedRisk, setSelectedRisk] = useState("");
  const myRef = useRef<any>(null);

  useEffect(() => {
    getModule();
  }, []);

  const getModule = () => {
    getCall("/module").then((data: any) => {
      setmoduleList([
        {
          id: "all",
          name: "All",
          supermodule: null,
        },
        ...data,
      ]);
    });
  };

  const getSubModule = (moduleId: string) => {
    getCall("/module/subModule/" + moduleId).then((data) => {
      if (data.length === 0 || !data) {
        // getps(moduleId);
        getHeatMapData(moduleId);
        setSelectedModuleId(moduleId);
      } else {
        setsubModuleList([
          {
            id: "all",
            name: "All",
            supermodule: null,
          },
          ...data,
        ]);
      }
    });
  };

  const getHeatMapData = (moduleId: string) => {
    getCall("/common/heatmap/" + moduleId).then((data) => {
      sethmData(data);
      // data = data.map((el: any, index: number) => {
      //     return { index: index + 1, ...el };
      // });
    });
  };

  const getHeatMapDetail = (moduleId: string, risk: string, status: string) => {
    setSelectedStatus(status);
    setSelectedRisk(risk);
    postCall("/common/heatmapdetail", {
      moduleid: moduleId,
      risk,
      status,
    }).then((data) => {
      setSelectedCellDetail(
        data.map((el: any, i: number) => {
          const moduleObject =
            moduleList.find((module: any) => module.id === el.modulename) || {};
          return { index: i + 1, ...el, moduleName: moduleObject.name };
        })
      );
      if (data && data.length && myRef && myRef.current) {
        myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  };

  const handleModuleChange = (value: any) => {
    setSelectedModule(value);

    // reset data
    setsubModuleList([]);
    setSelectedSubModule("");

    getSubModule(value.id);
  };

  const handleSubModuleChange = (value: any) => {
    setSelectedSubModule(value);
    getHeatMapData(value.id);
    setSelectedModuleId(value.id);
  };

  const deleteElemet = (id: string) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      deleteCall("/element/" + id).then(() => {
        // getElements();
        getHeatMapDetail(selectedModuleId, selectedRisk, selectedStatus);
      });
    }
  };

  // const hmData = [[{ ps: 1, el: 3 }, { ps: 2, el: 5 }, { ps: 6, el: 8 }, { ps: 1, el: 4 }, { ps: 5, el: 15 }]]

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <CustomIconButton
          onClick={() => {
            setSelectedViewElement(rowData);
          }}
          icon="detail.svg"
        ></CustomIconButton>
        <CustomIconButton
          onClick={() => {
            setselectedEditElement({ ...rowData, name: rowData.elementname });
          }}
          icon="edit.svg"
        ></CustomIconButton>
        <CustomIconButton
          onClick={() => {
            deleteElemet(rowData.id);
          }}
          icon="delete.svg"
        ></CustomIconButton>
      </div>
      // <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" onClick={() => setselectedEditPs(rowData)} />
    );
  };

  return (
    <div>
      <div className="container section">
        <div className="columns is-multiline">
          <div className="column is-6">
            <div className="box">
              <div className="columns">
                <div className="column is-6">
                  <CustomSelect
                    label="Module Name"
                    placeholder="Select Module"
                    selectedOp={selectedModule}
                    handleOpChange={handleModuleChange}
                    opList={moduleList}
                  ></CustomSelect>
                </div>
                <div className="column is-6">
                  <CustomSelect
                    label="Sub-Module Name"
                    placeholder="Select Sub-Module"
                    selectedOp={selectedSubModule}
                    handleOpChange={handleSubModuleChange}
                    opList={subModuleList}
                  ></CustomSelect>
                </div>
              </div>
              {Object.keys(hmData).length > 0 ? (
                <div className="mt-4">
                  <div className="heatmap-section">
                    <div className="heatmap-row">
                      <div className="col-header">
                        <span className="is-size-7">
                          Unacceptable Risk Exposure
                        </span>
                      </div>
                      {Object.keys(hmData["Unacceptable Risk Exposure"]).map(
                        (risk: any, i: number) => (
                          <div
                            onClick={() => {
                              getHeatMapDetail(
                                selectedModuleId,
                                risk,
                                "Unacceptable Risk Exposure"
                              );
                            }}
                            key={i}
                            className="has-text-centered hetmap-cell"
                            style={{
                              background: i === 4 ? "#DB4747" : "#E2B04D",
                              border: "1px solid #fff",
                            }}
                          >
                            {hmData["Unacceptable Risk Exposure"][risk].elcount}
                            ,
                            {hmData["Unacceptable Risk Exposure"][risk].pscount}
                          </div>
                        )
                      )}
                    </div>
                    <div className="heatmap-row">
                      <div className="col-header">
                        <span className="is-size-7">
                          Major improvement Opportunity
                        </span>
                      </div>
                      {Object.keys(hmData["Major Improvement Opportunity"]).map(
                        (risk: any, i: number) => (
                          <div
                            onClick={() => {
                              getHeatMapDetail(
                                selectedModuleId,
                                risk,
                                "Major Improvement Opportunity"
                              );
                            }}
                            key={i}
                            className="has-text-centered hetmap-cell"
                            style={{
                              background: i === 4 ? "#E2B04D" : "#FFEE96",
                              border: "1px solid #fff",
                            }}
                          >
                            {
                              hmData["Major Improvement Opportunity"][risk]
                                .elcount
                            }
                            ,
                            {
                              hmData["Major Improvement Opportunity"][risk]
                                .pscount
                            }
                          </div>
                        )
                      )}
                    </div>

                    <div className="heatmap-row">
                      <div className="col-header">
                        <span className="is-size-7">
                          Some improvement Opportunity
                        </span>
                      </div>
                      {Object.keys(hmData["Some Improvement Opportunity"]).map(
                        (risk: any, i: number) => (
                          <div
                            onClick={() => {
                              getHeatMapDetail(
                                selectedModuleId,
                                risk,
                                "Some Improvement Opportunity"
                              );
                            }}
                            key={i}
                            className="has-text-centered hetmap-cell"
                            style={{
                              background:
                                i === 3
                                  ? "#FFEE96"
                                  : i === 4
                                  ? "#E2B04D"
                                  : "#AECB78",
                              border: "1px solid #fff",
                            }}
                          >
                            {
                              hmData["Some Improvement Opportunity"][risk]
                                .elcount
                            }
                            ,
                            {
                              hmData["Some Improvement Opportunity"][risk]
                                .pscount
                            }
                          </div>
                        )
                      )}
                    </div>

                    <div className="heatmap-row">
                      <div className="col-header">
                        <span className="is-size-7">Satisfactory</span>
                      </div>
                      {Object.keys(hmData["Satisfactory"]).map(
                        (risk: any, i: number) => (
                          <div
                            key={i}
                            onClick={() => {
                              getHeatMapDetail(
                                selectedModuleId,
                                risk,
                                "Satisfactory"
                              );
                            }}
                            className="has-text-centered hetmap-cell"
                            style={{
                              background:
                                i === 2
                                  ? "#AECB78"
                                  : i === 3
                                  ? "#FFEE96   "
                                  : i === 4
                                  ? "#E2B04D"
                                  : "#629700",
                              border: "1px solid #fff",
                            }}
                          >
                            {hmData["Satisfactory"][risk].elcount},
                            {hmData["Satisfactory"][risk].pscount}
                          </div>
                        )
                      )}
                    </div>

                    <div className="heatmap-row">
                      <div className="col-header">
                        <span className="is-size-7">Well managed</span>
                      </div>
                      {Object.keys(hmData["Well Managed"]).map(
                        (risk: any, i: number) => (
                          <div
                            key={i}
                            onClick={() => {
                              getHeatMapDetail(
                                selectedModuleId,
                                risk,
                                "Well Managed"
                              );
                            }}
                            className="has-text-centered hetmap-cell"
                            style={{
                              background:
                                i === 2
                                  ? "#AECB78"
                                  : i === 3
                                  ? "#FFEE96   "
                                  : i === 4
                                  ? "#E2B04D"
                                  : "#629700",
                              border: "1px solid #fff",
                            }}
                          >
                            {hmData["Well Managed"][risk].elcount},
                            {hmData["Well Managed"][risk].pscount}
                          </div>
                        )
                      )}
                    </div>

                    <div className="heatmap-row">
                      <div className="col-header">
                        <span className="is-size-7"></span>
                      </div>
                      {[
                        "Unrated",
                        "Low Risk",
                        "Moderate Risk",
                        "High Risk",
                        "Extreme Risk",
                      ].map((obj) => (
                        <div className="has-text-centered hetmap-cell is-size-7">
                          {obj}{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="has-text-centered section">
                  <img
                    src={process.env.PUBLIC_URL + "/icons/emptyTable.svg"}
                    alt="logo"
                  />{" "}
                  <br />
                  Please make a selection to view the search results
                </div>
              )}
            </div>
            <br />
          </div>
          <div className="column is-6">
            <Stat></Stat>
          </div>
          <div ref={myRef}>
            {selectedCellDetail.length > 0 && (
              <div className="column is-12">
                <div className="box">
                  <div style={{ width: "100%" }}>
                    <DataTable
                      value={selectedCellDetail}
                      style={{ width: "100%" }}
                      paginatorClassName="p-jc-end"
                      className="p-mt-6"
                    >
                      <Column
                        field="index"
                        header="Sr No"
                        style={{ width: "5rem" }}
                      ></Column>

                      <Column field="elementname" header="Element"></Column>
                      <Column field="moduleName" header="Module"></Column>
                      <Column
                        field="submodulename"
                        header="Sub Module"
                      ></Column>

                      <Column
                        field="psname"
                        header="Performance Standard"
                      ></Column>

                      <Column
                        body={actionBodyTemplate}
                        style={{ textAlign: "center", width: "15em" }}
                      ></Column>
                    </DataTable>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedEditElement ? (
        <EditElementModel
          selectedEditElement={selectedEditElement}
          setselectedEditElement={setselectedEditElement}
        ></EditElementModel>
      ) : (
        ""
      )}

      {selectedViewElement && (
        <ViewElementModel
          selectedViewElement={selectedViewElement}
          setSelectedViewElement={setSelectedViewElement}
        />
      )}
    </div>
  );
};

export default HeatMap;

const hmData_ = {
  "Unacceptable Risk Exposure": {
    Unrated: {
      pscount: 0,
      elcount: 0,
    },
    "Low Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Moderate Risk": {
      pscount: 0,
      elcount: 0,
    },
    "High Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Extreme Risk": {
      pscount: 0,
      elcount: 0,
    },
  },
  "Major Improvement Opportunity": {
    Unrated: {
      pscount: 0,
      elcount: 0,
    },
    "Low Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Moderate Risk": {
      pscount: 0,
      elcount: 0,
    },
    "High Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Extreme Risk": {
      pscount: 0,
      elcount: 0,
    },
  },
  "Some Improvement Opportunity": {
    Unrated: {
      pscount: 0,
      elcount: 0,
    },
    "Low Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Moderate Risk": {
      pscount: 0,
      elcount: 0,
    },
    "High Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Extreme Risk": {
      pscount: 0,
      elcount: 0,
    },
  },
  Satisfactory: {
    Unrated: {
      pscount: 0,
      elcount: 0,
    },
    "Low Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Moderate Risk": {
      pscount: 0,
      elcount: 0,
    },
    "High Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Extreme Risk": {
      pscount: 0,
      elcount: 0,
    },
  },
  "Well Managed": {
    Unrated: {
      pscount: 0,
      elcount: 0,
    },
    "Low Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Moderate Risk": {
      pscount: 0,
      elcount: 0,
    },
    "High Risk": {
      pscount: 0,
      elcount: 0,
    },
    "Extreme Risk": {
      pscount: 0,
      elcount: 0,
    },
  },
};
