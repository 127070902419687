import { FunctionComponent, useEffect, useState } from "react";
import { getCall, postCall } from "../../../../services/api_service";
import CutomInput from "../../../../components/CustomInput";
import { CustomButton } from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";
import { unitTypeList } from "../../../../utils/utils";

interface CreateModuleModelProps {
  getUnits: Function;
  setShowCreateUnit: Function;
}

const CreateUnit: FunctionComponent<CreateModuleModelProps> = ({
  getUnits,
  setShowCreateUnit,
}) => {
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");

  const [type, settype] = useState<any>({});

  const [address, setaddress] = useState("");
  const [state, setstate] = useState("");
  const [entity, setEntity] = useState<any>("");
  const [entityList, setEntityList] = useState([]);

  const getEntities = () => {
    getCall("/users/entities").then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      setEntityList(data);
    });
  };

  const addUser = () => {
    if (name !== "") {
      postCall("/users/add-unit", {
        name,
        description,
        type:type.name,
        address,
        state,
        entity_id: entity.id,
      }).then(() => {
        getUnits();
        setShowCreateUnit(false);
      });
    }
  };

  useEffect(() => {
    getEntities();
  }, []);

  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setShowCreateUnit(null);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Add Unit</p>
            </div>
            <br />
            <div className="columns is-multiline">
              <div className="column is-6">
                <CutomInput
                  label="Name"
                  onChange={($event) => {
                    setname($event.target.value);
                  }}
                  value={name}
                ></CutomInput>
              </div>
              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    settype(value);
                  }}
                  opList={unitTypeList}
                  placeholder="Select Type"
                  selectedOp={type}
                ></CustomSelect>
              </div>
              <div className="column is-6">
                <CutomInput
                  label="Address"
                  onChange={($event) => {
                    setaddress($event.target.value);
                  }}
                  value={address}
                ></CutomInput>
                <div className="column is-6">
                  <CustomSelect
                    handleOpChange={(value: any) => {
                      setEntity(value);
                    }}
                    opList={entityList}
                    placeholder="Select Entity"
                    selectedOp={entity}
                  ></CustomSelect>
                </div>
                <div className="has-text-centered">
                  <CustomButton lable="Add" onClick={addUser}></CustomButton>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CreateUnit;
