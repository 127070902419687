import { Row, Col, Button } from "antd";
import React, { useState, useEffect, useRef } from "react";
import EditFile from "./components/actions/editDir";
import AddFile from "./components/addDirectory";
import FilesTable from "./components/FilesTable";
import { useParams } from "react-router-dom";
import FilterSelect from "../../../../components/FilterSelect";
import MoreOptions from "../../../../components/MoreOptions";
import { getCall, postCall } from "../../../../services/api_service";

const Files = () => {
  const [dataSource, setdataSource] = useState([]);
  const [filterdataSource, setfilterdataSource] = useState([]);
  const [selected, setSelected] = useState(null);
  const [modalToShow, setModalToShow] = React.useState("");
  const [loading, setloading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedFile, setSelectedFile] = React.useState({
    file: "",
    fileName: "",
  });
  const inputRef = useRef(null);

  const { id } = useParams();
  const setShowModel = () => {
    setModalToShow("");
  };

  useEffect(() => {
    // getGroup();
    getFiles();
  }, []);

  const getFiles = () => {
    getCall(`/directory/sub-directory/${id}`).then((res) => {
      const originData_ = res.map((row, index) => {
        return {
          index: index + 1,
          ...row,
          actions: (
            <MoreOptions
              actionsList={[
                {
                  label: "Edit File",
                  onClick: () => {
                    setModalToShow("Edit Files");
                    setSelected({ ...row });
                  },
                },
                {
                  label: "Upload Attachments",
                  onClick: () => {
                    setSelected({ ...row });
                    inputRef.current.click();
                  },
                },
                {
                  label: "Delete File",
                  onClick: () => {
                    deleteDir(row.id);
                  },
                },
              ]}
            />
          ),
        };
      });
      setdataSource(originData_);
      setfilterdataSource(originData_);
      // setFilterData(originData_);
    });
  };

  const deleteDir = (id) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      postCall("/directory/files/delete", { id }).then(() => {
        getFiles();
      });
    }
  };
  const onChange = async (info) => {
    console.log("onchange", info);
    if (info) {
      setSelectedFile({
        file: info,
        fileName: info.name,
      });
    }
  };

  useEffect(async () => {
    if (selectedFile.fileName) {
      setloading(true);
      const formData = new FormData();
      formData.append("file", selectedFile.file);
      formData.append("filesId", selected.id);

      try {
        const uploadFile = await postCall("/upload/filesAttachment", formData);
        if (uploadFile) {
          getFiles();

          // const { path } = uploadFile;
          // if (path && path.length) {
          //   // getRepo();
          //   const originDataLocal = [...originData];
          //   const complianceIndexLocal = originDataLocal.findIndex(
          //     (complianceInner) => complianceInner.id === selectedComplianceId
          //   );
          //   const pathFileName = path.split("/")[1];
          //   originDataLocal[complianceIndexLocal].attachment = [
          //     ...(originData[complianceIndexLocal].attachment || []),
          //     path,
          //   ];
          //   originDataLocal[complianceIndexLocal].attachments = [
          //     ...(originData[complianceIndexLocal].attachments || []),
          //     pathFileName,
          //   ];
          //   setOriginData(originDataLocal);
          //   setShowDueDateModal(false);
          //   alert("Upload Success");
          // }
        }
        setloading(false);
      } catch (e) {
        setloading(false);
        console.log("something went wrong while uploading file");
      }
    }
  }, [selectedFile]);

  return (
    <div className="section container is-fluid">
      <Row gutter={[20, 20]}>
        <Col span={8}>
          <FilterSelect
            placeholder="Search Files"
            options={dataSource}
            label="name"
            value="id"
            // selectedValue={selectedGroup}
            onChange={(el) => {
              const data = dataSource.filter((it) => it.id == el);
              setfilterdataSource(data);
            }}
          />
        </Col>
        <Col span={13}></Col>
        <Col>
          <Button type="secoundry" onClick={() => setModalToShow("Add Files")}>
            Add
          </Button>
        </Col>
      </Row>
      <br />

      <FilesTable
        dataSource={filterdataSource}
        parentDirectory={id}
        loading={loading}
        getFiles={getFiles}
      />

      <AddFile
        getFile={getFiles}
        showModel={modalToShow === "Add Files"}
        setShowModel={setShowModel}
        title={"Add File"}
        parentDirectory={id}
      />

      <EditFile
        getFile={getFiles}
        selected={selected}
        showModel={modalToShow === "Edit Files"}
        setShowModel={setShowModel}
        title={"Edit File"}
      />
      <input
        type="file"
        name="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => onChange(e.target.files[0])}
        onClick={(event) => {
          event.currentTarget.value = null;
        }}
      />
    </div>
  );
};

export default Files;
