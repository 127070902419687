import { FunctionComponent, useState, useRef } from "react";

import ExcelToJson from "./ExcelToJson";
import CutomInput from "../../../../components/CustomInput";
import { CustomButton } from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";

interface CreateStanderdsModelProps {
  setShowCreatPs: Function;
  moduleList: Array<any>;
  subModuleList: Array<any>;
  selectedModule: any;
  selectedSubModule: any;
  handleModuleChange: Function;
  handleSubModuleChange: Function;
  setxlData: Function;
  addPs: Function;
  name: string;
  setName: Function;
}

const CreateStanderdsModel: FunctionComponent<CreateStanderdsModelProps> = ({
  setShowCreatPs,
  moduleList,
  subModuleList,
  selectedModule,
  selectedSubModule,
  handleModuleChange,
  handleSubModuleChange,
  setxlData,
  addPs,
  name,
  setName,
}) => {
  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setShowCreatPs(false);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Add Performance Standards</p>
            </div>
            <br />

            <div className="columns">
              <div className="column ">
                <CustomSelect
                  label="Module Name"
                  placeholder="Select Module"
                  selectedOp={selectedModule}
                  handleOpChange={handleModuleChange}
                  opList={moduleList}
                ></CustomSelect>
              </div>
              <div className="column">
                <CustomSelect
                  label="Sub-Module Name"
                  placeholder="Select Sub-Module"
                  selectedOp={selectedSubModule}
                  handleOpChange={handleSubModuleChange}
                  opList={subModuleList}
                ></CustomSelect>
              </div>
            </div>

            <div>
              <CutomInput
                label="Performance Standard Name"
                onChange={($event) => {
                  setName($event.target.value);
                }}
                value={name}
              ></CutomInput>
            </div>
            <br />
            <p className="has-text-centered">or Add Bulk for Excel</p>
            <br />
            <ExcelToJson setxlData={setxlData}></ExcelToJson>
            <br />
            <div className="has-text-centered">
              <CustomButton lable="Add" onClick={() => addPs()}></CustomButton>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CreateStanderdsModel;
