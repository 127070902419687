import { FunctionComponent, useState, useEffect } from "react";
import ElementTable from "./components/ElementTable";
import CreateElementModel from "./components/CreateElement";
import EditElementModel from "./components/EditElement";
import module from "../../../interfaces/module";
import PerformanceStander from "../../../interfaces/performanceStander";
import { getCall } from "../../../services/api_service";
import { exportExcel } from "../../../utils/jsonToExcel";
import {
  CustomButton,
  CustomOutlineButton,
} from "../../../components/CustomButton";
import ViewElementModel from "./components/ViewElementDetail";
import { useLocation } from "react-router-dom";

interface QuestionPageProps {}

const QuestionPage: FunctionComponent<QuestionPageProps> = () => {
  const [showCreateElement, setShowCreateElement] = useState(false);
  const [selectedEditElement, setselectedEditElement] = useState<any>(null);
  const [selectedViewElement, setSelectedViewElement] = useState<any>(null);
  const [moduleList, setmoduleList] = useState<Array<module>>([]);
  const [subModuleList, setsubModuleList] = useState<Array<module>>([]);
  const [psList, setpsList] = useState<Array<PerformanceStander>>([]);
  const [elementList, setelementList] = useState<Array<PerformanceStander>>([]);

  const [selectedModule, setSelectedModule] = useState<any>("");
  const [selectedSubModule, setSelectedSubModule] = useState<any>("");
  const [selectedPs, setSelectedPs] = useState<any>("");
  const [selectedStatus, setSelectedStatus] = useState<any>("");

  const location = useLocation();
  const isApproved = !!location["search"].includes("?isApproved=true");
  const currentUserRole = localStorage.getItem("userRole") || "";


  useEffect(() => {
    getModule();

    let moduleData = localStorage.getItem("moduleData");
    let subModuleData = localStorage.getItem("subModuleData");
    let psData = localStorage.getItem("psData");

    if (moduleData) {
      setSelectedModule(JSON.parse(moduleData + ""));
      setSelectedSubModule(JSON.parse(subModuleData + ""));
      if (psData) {
        setSelectedPs({
          name: JSON.parse(psData).name,
          id: JSON.parse(psData).id,
          moduleid: JSON.parse(psData).moduleid,
        });
        getElements(JSON.parse(psData).id);
      }
      const moduleId = JSON.parse(moduleData).id;

      getSubModule(moduleId);

      if (subModuleData && JSON.parse(subModuleData).id !== "all") {
        const subModuleId = JSON.parse(subModuleData).id;
        getps(subModuleId);
      } else {
        getps(moduleId);
      }
    }
    localStorage.removeItem("moduleData");
    localStorage.removeItem("subModuleData");
    localStorage.removeItem("psData");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getModule = () => {
    getCall("/module").then((data) => {
      setmoduleList(data);
    });
  };

  const getSubModule = (moduleId: string) => {
    getCall("/module/subModule/" + moduleId).then((data) => {
      if (data.length === 0 || !data) {
        getps(moduleId);
        setSelectedSubModule({ name: "All", id: "all" });
      } else {
        setsubModuleList(data);
      }
    });
  };

  const getps = (moduleId: string) => {
    getCall("/ps/" + moduleId).then((data) => {
      setpsList(data);
    });
  };

  const getElements = (psId: string) => {
    const currentUserId = localStorage.getItem("userId") || "NA";
    getCall("/element/" + psId + "/" + currentUserId + "/" + isApproved).then(
      (data) => {
        data = data.map((el: any, index: number) => {
          return { index: index + 1, ...el };
        });
        setelementList(data);
      }
    );
  };

  const handleModuleChange = (value: any) => {
    setSelectedModule(value);

    // reset data
    setsubModuleList([]);
    setpsList([]);
    setelementList([]);
    setSelectedSubModule("");
    setSelectedPs("");

    getSubModule(value.id);
  };

  const handleSubModuleChange = (value: any) => {
    setSelectedSubModule(value);

    // reset data
    setpsList([]);
    setelementList([]);
    setSelectedPs("");

    getps(value.id);
  };

  const handlePSChange = (value: any) => {
    setSelectedPs(value);

    if (value.id === "all") {
      if (selectedSubModule.id === "all") {
        getElements("all-" + selectedModule.id);
      } else {
        getElements("all-" + selectedSubModule.id);
      }
    } else {
      getElements(value.id);
    }
    // reset data
    setelementList([]);

    // getElements(value.id);
  };

  const handleStatusChange = (value: any) => {
    setSelectedStatus(value);
  };

  return (
    <div className="container section">
      <CreateElementModel
        setShowCreateElement={setShowCreateElement}
        showCreateElement={showCreateElement}
        handleModuleChange={handleModuleChange}
        moduleList={moduleList}
        subModuleList={subModuleList}
        psList={psList}
        selectedModule={selectedModule}
        selectedSubModule={selectedSubModule}
        selectedPs={selectedPs}
        handlePSChange={handlePSChange}
        handleSubModuleChange={handleSubModuleChange}
        getElements={() => getElements(selectedPs.id)}
      ></CreateElementModel>

      {selectedEditElement ? (
        <EditElementModel
          selectedEditElement={selectedEditElement}
          setselectedEditElement={setselectedEditElement}
        ></EditElementModel>
      ) : (
        ""
      )}

      {selectedViewElement && (
        <ViewElementModel
          selectedViewElement={selectedViewElement}
          setSelectedViewElement={setSelectedViewElement}
        />
      )}

      <div className="box">
        <div className="columns">
          <div className="column">
            <p className="title">Element List</p>
          </div>
          <div className="column has-text-right">
            <CustomOutlineButton
              lable="Download Excel File"
              onClick={() =>
                exportExcel(
                  elementList.map((ps: any) => {
                    return { "performance Standards": ps.name };
                  }),
                  selectedPs
                )
              }
            ></CustomOutlineButton>
            {['admin', 'L3'].includes(currentUserRole) && <CustomButton
              lable="Add"
              onClick={() => {
                setShowCreateElement(true);
              }}
            ></CustomButton>}
          </div>
        </div>
        <ElementTable
          setShowCreateElement={setShowCreateElement}
          setselectedEditElement={setselectedEditElement}
          setSelectedViewElement={setSelectedViewElement}
          getElements={() => getElements(selectedPs)}
          selectedModule={selectedModule}
          selectedSubModule={selectedSubModule}
          selectedPs={selectedPs}
          moduleList={moduleList}
          subModuleList={subModuleList}
          handleModuleChange={handleModuleChange}
          handleSubModuleChange={handleSubModuleChange}
          handlePSChange={handlePSChange}
          psList={psList}
          handleStatusChange={handleStatusChange}
          selectedStatus={selectedStatus}
          elementList={elementList}
        ></ElementTable>
      </div>
    </div>
  );
};

export default QuestionPage;
