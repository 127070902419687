import { FunctionComponent, useEffect, useState } from "react";
import { deleteCall, getCall } from "../../../services/api_service";
import { User, Entity, Unit } from "../../../interfaces/user";
import { exportExcel } from "../../../utils/jsonToExcel";
import {
  CustomButton,
  CustomOutlineButton,
} from "../../../components/CustomButton";
import { TabView, TabPanel } from "primereact/tabview";
import CreateUser from "./components/CreateUaser";
import CreateEntity from "./components/CreateEntity";
import EditEntity from "./components/EditEntity";
import CreateUnit from "./components/CreateUnit";
import EditUnit from "./components/EditUnit";
import EditUser from "./components/EditUser";
import UsersTable from "./components/UsersTabel";
import EntityTable from "./components/EntityTable";
import UnitTable from "./components/UnitTabel";
import module from "../../../interfaces/module";

interface UasrPageProps {}

const UasePage: FunctionComponent<UasrPageProps> = () => {
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showCreateEntity, setShowCreateEntity] = useState(false);
  const [showCreateUnit, setShowCreateUnit] = useState(false);

  const [usersList, setUsersList] = useState<Array<User>>([]);
  const [entityList, setEntityList] = useState<Array<Entity>>([]);
  const [unitList, setUnitsList] = useState<Array<Unit>>([]);

  const [selectedEditUser, setselectedEditUser] = useState();
  const [selectedEditEntity, setselectedEditEntity] = useState();
  const [selectedEditUnit, setselectedEditUnit] = useState();

  const [moduleList, setmoduleList] = useState<Array<module>>([]);

  const [activeIndex, setActiveIndex] = useState(0);
  const role = localStorage.getItem("userRole");

  useEffect(() => {
    getUsers();
    getEntities();
    getUnits();
    getModule();
  }, []);

  const getModule = () => {
    getCall("/module").then((data) => {
      setmoduleList(data);
    });
  };

  const getUsers = () => {
    getCall("/users").then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      setUsersList(data);
    });
  };
  const getEntities = () => {
    getCall("/users/entities").then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      setEntityList(data);
    });
  };
  const getUnits = () => {
    getCall("/users/all-units").then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      setUnitsList(data);
    });
  };
  const deleteUser = (id: string) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      deleteCall("/users/" + id).then(() => {
        getUsers();
      });
    }
  };
  const deleteEntity = (id: string) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      deleteCall("/users/entity/" + id).then(() => {
        getEntities();
      });
    }
  };
  const deleteUnit = (id: string) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      deleteCall("/users/unit/" + id).then(() => {
        getUnits();
      });
    }
  };

  return (
    <div className="section container is-fluid">
      {/* <div className="card p-4">
                <div className="columns is-vcentered">
                    <div className="column is-8">
                        <p className="page-title"> Users </p>
                    </div>
                    <div className="column is-4 has-text-right">
                        {showCreateUser ? <button className="button is-info" onClick={() => { setShowCreateUser(false) }} >
                            <AiOutlineOrderedList />  <span className="ml-3" > View Users </span>
                        </button> : <button className="button is-info" onClick={() => { setShowCreateUser(true) }} >
                            <BsPlusLg />  <span className="ml-3" > Add User </span>
                        </button>}
                    </div>
                </div>
            </div>

            <br /> */}
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Entities">
          {showCreateEntity ? (
            <CreateEntity
              getEntities={getEntities}
              setShowCreateEntity={setShowCreateEntity}
              moduleList={moduleList}
            ></CreateEntity>
          ) : (
            <div className="box">
              <div className=" columns">
                <div className="column">
                  <p className="title">List of Entities</p>
                </div>
                <div className="column has-text-right">
                  <div className="column has-text-right">
                    <CustomOutlineButton
                      lable="Download Excel File"
                      onClick={() =>
                        exportExcel(
                          entityList.map((user: any) => {
                            return {
                              userName: user.name,
                            };
                          }),
                          "users"
                        )
                      }
                    ></CustomOutlineButton>
                    <CustomButton
                      lable="Add"
                      onClick={() => {
                        setShowCreateEntity(true);
                      }}
                    ></CustomButton>
                  </div>

                  {/* <Button type="button" icon="pi pi-file-excel" onClick={() => exportExcel(usersList.map((user: any) => { return { 'userName': user.name, 'email': user.email, "role": user.role } }), 'users')} className="p-button-success p-mr-2" data-pr-tooltip="XLS" /> */}
                </div>
              </div>

              <EntityTable
                data={entityList}
                setselectedEditUser={setselectedEditEntity}
                deleteUser={deleteEntity}
              ></EntityTable>
            </div>
          )}
          {selectedEditEntity ? (
            <EditEntity
              getUsers={getEntities}
              selectedEditUser={selectedEditEntity}
              setselectedEditUser={setselectedEditEntity}
              moduleList={moduleList}
            ></EditEntity>
          ) : (
            ""
          )}
        </TabPanel>
        <TabPanel header="Units">
          {showCreateUnit ? (
            <CreateUnit
              getUnits={getUnits}
              setShowCreateUnit={setShowCreateUnit}
            ></CreateUnit>
          ) : (
            <div className="box">
              <div className=" columns">
                <div className="column">
                  <p className="title">List of Units</p>
                </div>
                <div className="column has-text-right">
                  <div className="column has-text-right">
                    <CustomOutlineButton
                      lable="Download Excel File"
                      onClick={() =>
                        exportExcel(
                          unitList.map((user: any) => {
                            return {
                              userName: user.name,
                            };
                          }),
                          "users"
                        )
                      }
                    ></CustomOutlineButton>
                    <CustomButton
                      lable="Add"
                      onClick={() => {
                        setShowCreateUnit(true);
                      }}
                    ></CustomButton>
                  </div>

                  {/* <Button type="button" icon="pi pi-file-excel" onClick={() => exportExcel(usersList.map((user: any) => { return { 'userName': user.name, 'email': user.email, "role": user.role } }), 'users')} className="p-button-success p-mr-2" data-pr-tooltip="XLS" /> */}
                </div>
              </div>

              <UnitTable
                data={unitList}
                setselectedEditUser={setselectedEditUnit}
                deleteUser={deleteUnit}
              ></UnitTable>
            </div>
          )}
          {selectedEditUnit && (
            <EditUnit
              getUsers={getUnits}
              selectedEditUser={selectedEditUnit}
              setselectedEditUser={setselectedEditUnit}
            ></EditUnit>
          )}
        </TabPanel>
        <TabPanel header="Users">
          {showCreateUser ? (
            <CreateUser
              getUsers={getUsers}
              setShowCreateUser={setShowCreateUser}
            ></CreateUser>
          ) : (
            <div className="box">
              <div className=" columns">
                <div className="column">
                  <p className="title">List of Users</p>
                </div>
                <div className="column has-text-right">
                  <div className="column has-text-right">
                    <CustomOutlineButton
                      lable="Download Excel File"
                      onClick={() =>
                        exportExcel(
                          usersList.map((user: any) => {
                            return {
                              userName: user.name,
                              email: user.email,
                              role: user.role,
                            };
                          }),
                          "users"
                        )
                      }
                    ></CustomOutlineButton>
                    <CustomButton
                      lable="Add"
                      onClick={() => {
                        setShowCreateUser(true);
                      }}
                    ></CustomButton>
                  </div>

                  {/* <Button type="button" icon="pi pi-file-excel" onClick={() => exportExcel(usersList.map((user: any) => { return { 'userName': user.name, 'email': user.email, "role": user.role } }), 'users')} className="p-button-success p-mr-2" data-pr-tooltip="XLS" /> */}
                </div>
              </div>

              <UsersTable
                data={usersList}
                setselectedEditUser={setselectedEditUser}
                deleteUser={deleteUser}
              ></UsersTable>
            </div>
          )}
          {selectedEditUser ? (
            <EditUser
              getUsers={getUsers}
              selectedEditUser={selectedEditUser}
              setselectedEditUser={setselectedEditUser}
            ></EditUser>
          ) : (
            ""
          )}
        </TabPanel>
      </TabView>
    </div>
  );
};

export default UasePage;
