import React from "react";
// import EditableRowsTable from "../../components/data-display/table/editableRows";
import { Row } from "antd";
import CustomTable from "../../../../../components/forms/CustomTable";

import { postCall } from "../../../../../services/api_service";

// import ConfirmModal from "../../modals/ConfirmModal";
// import SuccessModal from "../../modals/SuccessModal";

function FilesTable({ dataSource, parentDirectory, getFiles, loading }) {
  const deleteAttachemnt = (id, attachmentName) => {
    postCall(`/upload/delete/filesAttachment`, {
      filename: attachmentName,
      filesId: id 
    });
    getFiles();
  };
  const columns = [
    {
      title: "SR NO",
      dataIndex: "index",
      width: "20px",
      editable: false,
    },

    {
      title: "NAME",
      dataIndex: "name",
      width: "40px",
    },
    {
      title: "Attachment",
      dataIndex: "attachment",
      width: "20px",
      render: (name, row) =>
        (row.attachment || []).map((attachment) => (
          <>
            <a href={attachment} target={"_blank"}>
              {attachment.split("/")[attachment.split("/").length - 1]}
            </a>
            <button onClick={() => deleteAttachemnt(row.id, attachment)}>
              X
            </button>
          </>
        )),
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      width: "20px",
      editable: true,
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      width: "20px",
      editable: true,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      width: "40px",
    },

    // {
    //   title: "ENTITY ADMIN",
    //   dataIndex: "entity_admin",
    //   key: "entity_admin",
    //   width: "40px",
    //   render: (users) => (
    //     <>
    //       {users?.map((user) => {
    //         return <Tag key={user}>{user.toUpperCase()}</Tag>;
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "actions",
      dataIndex: "actions",
      width: "40px",
    },
  ];

  return (
    <div>
      <Row gutter={[0, 5]} justify="end"></Row>
      <CustomTable
        columns={columns}
        dataSource={dataSource}
        loading={loading}
      />
    </div>
  );
}

export default FilesTable;
