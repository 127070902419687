import { FunctionComponent, useState, useEffect, useRef } from "react";

import { RouteComponentProps } from "react-router-dom";
import CreateStanderdsModel from "./components/CreateStanderds";
import PsTable from "./components/PsTable";
import { getCall, postCall } from "../../../services/api_service";
import { Toast } from "primereact/toast";

import {
  CustomButton,
  CustomOutlineButton,
} from "../../../components/CustomButton";
import CustomSelect from "../../../components/CustomSelect";
import { exportExcel } from "../../../utils/jsonToExcel";

interface PerformanceStandardsPageProps {
  name: string;
}

const PerformanceStandardsPage: FunctionComponent<
  PerformanceStandardsPageProps & RouteComponentProps<any>
> = ({ match, name }) => {
  const [showCreatPs, setShowCreatPs] = useState(false);

  const [moduleList, setmoduleList] = useState<any>([]);
  const [subModuleList, setsubModuleList] = useState<any>([]);

  const [psList, setpsList] = useState<any>([]);

  const [selectedModule, setSelectedModule] = useState<any>("");
  const [selectedModuleName, setSelectedModuleName] = useState("");
  const [selectedSubModule, setSelectedSubModule] = useState<any>("");

  const [xlData, setxlData] = useState<Array<any>>([]);
  const [psNameToAdd, setpsNameToAdd] = useState("");

  const toast = useRef<any>(null);

  useEffect(() => {
    getModule();
  }, []);

  const getModule = () => {
    getCall("/module").then((data) => {
      setmoduleList(data);
    });
  };

  const getSubModule = (moduleId: string) => {
    getCall("/module/subModule/" + moduleId).then((data) => {
      if (data.length === 0 || !data) {
        setSelectedSubModule({ name: "All", id: "all" });
        getps(moduleId);
      } else {
        setsubModuleList(data);
      }
    });
  };

  const getps = (moduleId: string) => {
    getCall("/ps/" + moduleId).then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      setpsList(data);
    });
  };

  const handleModuleChange = (value: any) => {
    setSelectedModule(value);
    setSelectedModuleName(value.name);

    // reset data
    setsubModuleList([]);
    setSelectedSubModule("");

    getSubModule(value.id);
  };

  const handleSubModuleChange = (value: any) => {
    setSelectedSubModule(value);
    setSelectedModuleName(value.name);
    if (value.id === "all") {
      getps("all-" + selectedModule.id);
    } else {
      getps(value.id);
    }
  };

  const addPs = () => {
    let moduleId = selectedModule.id;

    if (selectedSubModule !== "") {
      moduleId =
        selectedSubModule.id !== "all"
          ? selectedSubModule.id
          : selectedModule.id;
    }

    if (xlData.length > 0) {
      postCall("/ps/bulkAdd", { pss: xlData, moduleId })
        .then((data) => {
          console.log(data);

          toast.current.show({
            severity: "success",
            summary: "",
            detail: "Added Successfully",
            life: 3000,
          });
          getps(
            selectedSubModule.id !== "all"
              ? selectedSubModule.id
              : selectedModule.id
          );
          setShowCreatPs(false);
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error Message",
            detail: "Could not add all the record.",
            life: 3000,
          });
        });
    } else if (psNameToAdd !== "") {
      postCall("/ps", { name: psNameToAdd, moduleId })
        .then((data) => {
          console.log(data);

          toast.current.show({
            severity: "success",
            summary: "",
            detail: "Added Successfully",
            life: 3000,
          });
          getps(
            selectedSubModule.id !== "all"
              ? selectedSubModule.id
              : selectedModule.id
          );
          setShowCreatPs(false);
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "Error Message",
            detail: "Could not add the record.",
            life: 3000,
          });
        });
    }
  };

  return (
    <div className="section container">
      {showCreatPs ? (
        <CreateStanderdsModel
          moduleList={moduleList}
          subModuleList={subModuleList}
          selectedModule={selectedModule}
          selectedSubModule={selectedSubModule}
          handleSubModuleChange={handleSubModuleChange}
          handleModuleChange={handleModuleChange}
          setxlData={setxlData}
          addPs={addPs}
          setShowCreatPs={setShowCreatPs}
          name={psNameToAdd}
          setName={setpsNameToAdd}
        ></CreateStanderdsModel>
      ) : (
        ""
      )}

      <div className="box">
        <div className=" columns">
          <div className="column">
            <p className="title">List of Performance Standards</p>
          </div>

          <div className="column has-text-right">
            <CustomOutlineButton
              lable="Download Excel File"
              onClick={() =>
                exportExcel(
                  psList.map((ps: any) => {
                    return { "performance Standards": ps.name };
                  }),
                  selectedModuleName
                )
              }
            ></CustomOutlineButton>
            <CustomButton
              lable="Add"
              onClick={() => {
                setShowCreatPs(true);
              }}
            ></CustomButton>
          </div>
        </div>

        <div className="columns">
          <div className="column ">
            <CustomSelect
              label="Module Name"
              placeholder="Select Module"
              selectedOp={selectedModule}
              handleOpChange={handleModuleChange}
              opList={moduleList}
            ></CustomSelect>
          </div>
          <div className="column">
            <CustomSelect
              label="Sub-Module Name"
              placeholder="Select Sub-Module"
              selectedOp={selectedSubModule}
              handleOpChange={handleSubModuleChange}
              opList={subModuleList}
              hasAllOp={true}
            ></CustomSelect>
          </div>
        </div>

        <PsTable
          setShowCreatPs={setShowCreatPs}
          getps={getps}
          selectedSubModule={selectedSubModule}
          selectedModule={selectedModule}
          psList={psList}
        ></PsTable>
      </div>

      <Toast ref={toast} />
    </div>
  );
};

export default PerformanceStandardsPage;
