import { FunctionComponent, useState } from "react";
import { putCall } from "../../../../services/api_service";
import { CustomButton } from "../../../../components/CustomButton";
import CutomInput from "../../../../components/CustomInput";

interface EditpsProps {
    selectedps: any,
    setSelectedps: Function,
    getps: Function
}

const Editps: FunctionComponent<EditpsProps> = ({ selectedps, setSelectedps, getps }) => {

    const [name, setname] = useState(selectedps.name);

    const update = () => {
        if (name !== '') {
            putCall('/ps/' + selectedps.id, { name: name, moduleId: selectedps.moduleid }).then(() => {
                getps(selectedps.moduleid);
                setSelectedps(null);
            });
        }
    }


    return (
        <div className="modal is-active ">
            <div className="modal-background"></div>
            <div className="modal-card">
                <section className="modal-card-body">
                    <div>
                        <button className="delete" aria-label="close" onClick={() => { setSelectedps(null) }} style={{ float: 'right' }} ></button>
                        <p className="modal-card-title" >Edit Performance Standards</p>
                    </div>
                    <br />
                    <div>
                        <CutomInput label="Module Name" onChange={($event) => { setname($event.target.value) }} value={name} ></CutomInput>
                    </div>
                    <br />
                    <div className="has-text-centered">
                        <CustomButton lable="Save" onClick={update} ></CustomButton>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Editps;