import { FunctionComponent, useEffect, useRef, useState } from "react";
import { getCall, putCall } from "../../../../services/api_service";
import CustomSelect from "../../../../components/CustomSelect";
import { CustomButton } from "../../../../components/CustomButton";
import { Toast } from "primereact/toast";

interface EditElementModelProps {
  selectedEditElement: any;
  setselectedEditElement: Function;
}

const EditElementModel: FunctionComponent<EditElementModelProps> = ({
  selectedEditElement,
  setselectedEditElement,
}) => {
  const toast = useRef<any>(null);
  const [name, setname] = useState("");
  const [userList, setUserList] = useState<Array<any>>([]);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [currentUser, setCurrentUser] = useState<any>("");

  useEffect(() => {
    setname(selectedEditElement.name);
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = () => {
    getCall("/users").then((data) => {
      const foundUser = data.find(
        (el: any) => el.id === selectedEditElement.assingedto
      );
      setUserList(data);
      if (foundUser) {
        setSelectedUser(foundUser);
        setCurrentUser(foundUser);
      }
    });
  };

  const handleUserChange = (value: any) => {
    setSelectedUser(value);
  };

  const updateElement = () => {
    putCall("/element", {
      ...selectedEditElement,
      auditStatus: selectedEditElement.auditstatus,
      assingedto: selectedUser.id,
    }).then((data) => {
      toast.current.show({
        severity: "success",
        summary: "",
        detail: "Updated Successfully",
        life: 3000,
      });
    });
  };

  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <Toast ref={toast} />
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setselectedEditElement(null);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Change User</p>
            </div>
            <br />

            <div className="columns is-multiline">
              <div className="column is-12">
                <br />
                <label className="lable">Element:</label>
                <br />
                <strong>{selectedEditElement.name}</strong>
                <br />
              </div>
              <div className="column is-6">
                <div>
                  <div>
                    <label className="lable">Currently Assigned to User:</label>
                    <br />
                    <strong> {currentUser.name} </strong>
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <CustomSelect
                  label="Assign to"
                  placeholder="Select user"
                  selectedOp={selectedUser}
                  handleOpChange={handleUserChange}
                  opList={userList}
                ></CustomSelect>
              </div>
            </div>

            <div className="has-text-centered">
              <CustomButton
                lable="Assign"
                onClick={updateElement}
              ></CustomButton>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditElementModel;
