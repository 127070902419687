import { FunctionComponent } from "react";

import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { exportExcel } from "../../../../utils/jsonToExcel";
import {
  CustomButton,
  CustomIconButton,
  CustomOutlineButton,
} from "../../../../components/CustomButton";
import { deleteCall } from "../../../../services/api_service";

interface ModuleTableProps {
  nodes: any[];
  modules: any[];
  setSelectedModule: Function;
  setShowModuleModel: Function;
  setShowSubModuleModel: Function;
  getModule: Function;
}

const ModuleTable: FunctionComponent<ModuleTableProps> = ({
  nodes,
  modules,
  setSelectedModule,
  setShowModuleModel,
  setShowSubModuleModel,
  getModule,
}) => {
  const deleteModue = (id: string) => {
    console.log(id);

    if (window.confirm("Delete the item?")) {
      deleteCall("/module/" + id).then(() => {
        setSelectedModule(null);
        getModule();
      });
    }
  };

  const actionTemplate = (node: any, column: any) => {
    return (
      <div>
        <CustomIconButton
          onClick={() => {
            setSelectedModule(node.data);
          }}
          icon="edit.svg"
        ></CustomIconButton>
        <CustomIconButton
          onClick={() => {
            deleteModue(node.data.id);
          }}
          icon="delete.svg"
        ></CustomIconButton>

        {/* <Button type="button" icon="pi pi-pencil" onClick={() => setSelectedModule(node.data)} className="p-button-rounded p-button-text"></Button> */}
      </div>
    );
  };

  return (
    <div className="box">
      <div className=" columns">
        <div className="column">
          <p className="title">List of All Modules</p>
        </div>
        <div className="column has-text-right">
          <CustomOutlineButton
            lable="Download Excel File"
            onClick={() => exportExcel(modules, "Modules")}
          ></CustomOutlineButton>
          <CustomButton
            lable="Add Module"
            onClick={() => setShowModuleModel(true)}
          ></CustomButton>
          <CustomButton
            lable="Add Sub-Module"
            onClick={() => setShowSubModuleModel(true)}
          ></CustomButton>
        </div>
      </div>

      <TreeTable value={nodes}>
        <Column expander style={{ width: "4em" }}></Column>
        <Column field="index" header="Sr No" style={{ width: "8em" }}></Column>
        <Column field="name" header="Module Name"></Column>
        <Column
          body={actionTemplate}
          style={{ textAlign: "center", width: "10em" }}
        />
      </TreeTable>
    </div>
  );
};

export default ModuleTable;
