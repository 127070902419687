import IRoute from "../interfaces/route";
import PerformanceStandardsPage from "../pages/admin/performance_standards/PerformanceStandards";
import ModulePage from "../pages/admin/modules_page/ModulePage";
import QuestionPage from "../pages/admin/elements_page/ElementsPage";
import AssistElement from "../pages/assist_element_page/AssistElementPage";
import UaserPage from "../pages/admin/users_page/UaserPage";
import Directory from "../pages/admin/directory/folder-section"
import SubDirectory from "../pages/admin/directory/sub-folder-section";
import Files from "../pages/admin/directory/files-section";
import DocumentLibrary from "../pages/admin/docsLibrary/index";
import Signup from "../pages/signup_page/Signup";
import DashboadPage from "../pages/admin/dashboad_page/DashboadPage";

const routes: IRoute[] = [
  {
    path: "/",
    name: "singup",
    component: Signup,
    exact: true,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboadPage,
    exact: true,
  },
  {
    path: "/module",
    name: "Modules",
    component: ModulePage,
    exact: true,
  },
  {
    path: "/Performance-standards",
    name: "Performance Standards",
    component: PerformanceStandardsPage,
    exact: true,
  },

  {
    path: "/elements",
    name: "Elements",
    component: QuestionPage,
    exact: true,
  },
  {
    path: "/master",
    name: "Master",
    component: UaserPage,
    exact: true,
  },
  {
    path: "/directory",
    name: "Directory",
    component: Directory,
    exact: true,
  },
  {
    path: "/directory/:id",
    name: "Sub Directory",
    component: SubDirectory,
    exact: true,
  },
  {
    path: "/product-standard",
    name: "Product Standard",
    component: Directory,
    exact: true,
  },
  {
    path: "/product-standard/:id",
    name: "Product Sub Directory",
    component: SubDirectory,
    exact: true,
  },
  {
    path: "/sub-directory/:id",
    name: "Files",
    component: Files,
    exact: true,
  },
  {
    path: "/docs-lib",
    name: "Documents Library",
    component: DocumentLibrary,
    exact: true,
  },
  // {
  //   path: "/master",
  //   name: "Master",
  //   component: Master,
  //   exact: true,
  // },
  {
    path: "/assist",
    name: "Report Elements",
    component: AssistElement,
    exact: true,
  },
];

export default routes;
