import { FunctionComponent, useRef, useState } from "react";
import { CustomButton } from "../../../components/CustomButton";
import { FileUpload } from "primereact/fileupload";
import { postCall } from "../../../services/api_service";
import axios from "axios";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";

interface AssistAttachmentsModelProps {
  selectedElement: any;
  setSelectedElement: Function;
}

const AssistAttachmentsModel: FunctionComponent<AssistAttachmentsModelProps> =
  ({ selectedElement, setSelectedElement }) => {
    console.log(selectedElement);
    const currentUserRole = localStorage.getItem("userRole") || "";
    const { id } = selectedElement;
    const fileUploadRef: any = useRef(null);
    const toast: any = useRef(null);
    const invoiceUploadHandler = ({ files }: any) => {
      const [file] = files;
      const fileReader = new FileReader();
      fileReader.onload = (event: any) => {
        const data = new FormData();
        data.append("file", file);
        data.append("elementId", id);

        const config: any = {
          method: "post",
          url: "https://bbbe-backend.esgfit.in/upload/attachment",
          headers: {
            "content-type": "multipart/form-data",
          },
          data: data,
        };

        axios(config)
          .then((response) => {
            fileUploadRef.current.clear();
            toast.current.show({
              severity: "success",
              summary: "Success",
              detail: "File Uploaded",
            });
          })
          .catch(function (error) {
            console.log(error);
          });
      };
      fileReader.readAsDataURL(file);
    };
    return (
      <div>
        <div className="modal is-active ">
          <div className="modal-background"></div>
          <div className="modal-card">
            <section className="modal-card-body">
              <Toast ref={toast}></Toast>
              <div>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => {
                    setSelectedElement(null);
                  }}
                  style={{ float: "right" }}
                ></button>
                <p className="modal-card-title">Attachment Details</p>
              </div>
              <br />

              {currentUserRole !== "Auditor" && (
                <>
                  <FileUpload
                    name="file"
                    customUpload={true}
                    uploadHandler={invoiceUploadHandler}
                    chooseLabel="Upload File"
                    ref={fileUploadRef}
                  ></FileUpload>
                  <br />
                  <br />
                </>
              )}
              <div className="columns">
                <div className="column">
                  <strong> Attachements List: </strong>
                </div>
              </div>
              {selectedElement.attachments.length
                ? selectedElement.attachments.map((attachment: any) => {
                    return (
                      <div className="columns">
                        <div className="column">
                          <a
                            target="_blank"
                            href={
                              attachment
                            }
                          >
                            {attachment}
                          </a>
                        </div>
                      </div>
                    );
                  })
                : null}
              <div className="has-text-centered">
                <CustomButton
                  lable="Ok"
                  onClick={() => {
                    setSelectedElement(null);
                  }}
                ></CustomButton>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  };

export default AssistAttachmentsModel;
