export const roleListAdmin = [
  { id: "L1", name: "L1" },
  { id: "L2", name: "L2" },
  { id: "L3", name: "L3" },
  { id: "Auditor", name: "Auditor" },
];

export const roleListUnit = [
  { id: "L1", name: "L1" },
  { id: "L2", name: "L2" },
  { id: "Auditor", name: "Auditor" },
];

export const roleList =
  localStorage.getItem("userRole") == "admin" ? roleListAdmin : roleListUnit;

export const entityTypeList = [
  { id: "Public Ltd", name: "Public Ltd" },
  { id: "Private Ltd", name: "Private Ltd" },
  { id: "LLP", name: "LLP" },
];

export const unitTypeList = [
  { id: "Corporate Office", name: "Corporate Office" },
  { id: "Regional Office", name: "Regional Office" },
  { id: "Warehouse", name: "Warehouse" },
  { id: "Factory", name: "Factory" },
  { id: "Other", name: "Other" },
];
