import React, { useState, useEffect } from "react";
import { Modal, notification, Row, Col, Button } from "antd";
import { RiCloseFill, RiErrorWarningFill } from "react-icons/ri";
import { putCall } from "../../../../../../../services/api_service";
import GenerateForm from "../../../../../../../components/forms/GenerateForm";


function EditFile({
  showModel,
  setShowModel,
  selected,
  title,
  getFile,
}) {
  const [loading, setloading] = useState();
  const [formData, setFormData] = useState();
  useEffect(() => {
    if(selected){
    const form = {name: selected.name, remarks: selected.remarks, due_date: new Date(selected.due_date).toLocaleDateString()}
        console.log("selected", form);

    setFormData({...form});
    }
  }, [selected]);

  const onSubmit = async () => {
    const { name } = formData;

    if (name !== "") {
      try {
          const addDir = await putCall(`/directory/files`, {
            ...formData,
            id: selected.id
          });
          if (addDir && addDir.success) {
            notification.open({
              message: "Data Updated Successfully",
              description: "",
              icon: <RiErrorWarningFill style={{ color: "#00BA75" }} />,
              closeIcon: (
                <RiCloseFill
                  className="remix-icon da-text-color-black-80"
                  size={24}
                />
              ),
            });
          }

          getFile();
          setShowModel(false);
        
      } catch (e) {
        let errorMsg = "something went wrong.";

        if (e.response) {
          if (e.response?.message) {
            errorMsg = e?.response?.message;
          } else if (e?.response?.data?.message) {
            errorMsg = e?.response?.data?.message;
            console.log(errorMsg);
          }
        }
        notification.open({
          message: "Error",
          description: e?.response?.data?.message,
          icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
          closeIcon: (
            <RiCloseFill
              className="remix-icon da-text-color-black-80"
              size={24}
            />
          ),
        });


        // handleModleClose();
      }
    } else {
      notification.open({
        message: "Error",
        description: "All fields are mandatory",
        icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
        closeIcon: (
          <RiCloseFill
            className="remix-icon da-text-color-black-80"
            size={24}
          />
        ),
      });
    }
  };

  return (
    <div>
      {selected && (
        <Modal
          centered
          title={title}
          visible={showModel}
          onOk={() => setShowModel(false)}
          onCancel={() => setShowModel(false)}
          width={1000}
          footer={[
            <Button
              key="submit"
              type="secoundry"
              loading={loading}
              onClick={onSubmit}
            >
              Update
            </Button>,
          ]}
        >
          <Row gutter={15}>
            <Col span={24}>
              {formData && <GenerateForm
                formField={formField}
                formData={formData}
                setFormData={setFormData}
                colSpan={8}
              />}
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
}

export default EditFile;


const formField = [
  {
    label: "File name",
    placeholder: "Enter File name",
    field: "name",
    inputType: "text",
  },
  {
    label: "Remarks",
    placeholder: "Enter Remarks",
    field: "remarks",
    inputType: "text",
  },
  {
    label: "Due Date",
    placeholder: "Enter Due Date",
    field: "due_date",
    inputType: "text",
  },
];
