import { FunctionComponent, useEffect, useState } from "react";
import { CustomButton } from "../../../../components/CustomButton";
import CutomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import { putCall } from "../../../../services/api_service";
import { roleList, unitTypeList } from "../../../../utils/utils";

interface EditUserProps {
  getUsers: Function;
  selectedEditUser: any;
  setselectedEditUser: Function;
}

const EditUnit: FunctionComponent<EditUserProps> = ({
  selectedEditUser,
  getUsers,
  setselectedEditUser,
}) => {
  const [name, setname] = useState("");
  
  const [description, setdescription] = useState("");

  const [type, settype] = useState<any>({});

  const [address, setaddress] = useState("");
  const [state, setstate] = useState("");

  useEffect(() => {
    setname(selectedEditUser.name);
    setdescription(selectedEditUser.description);
    setaddress(selectedEditUser.address);
    settype({name:selectedEditUser.type, id:selectedEditUser.type});
  }, [selectedEditUser]);

  const updateUser = () => {
    if (name !== "") {
      putCall("/users/unit/" + selectedEditUser.id, {
        name,
        description,
        type:type.name,
        address,
        state,
      }).then(() => {
        getUsers();
        setselectedEditUser(null);
      });
    }
  };

  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setselectedEditUser(null);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Edit Unit</p>
            </div>
            <br />
            <div className="columns is-multiline">
              <div className="column is-6">
                <CutomInput
                  label="Name"
                  onChange={($event) => {
                    setname($event.target.value);
                  }}
                  value={name}
                ></CutomInput>
              </div>

              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    settype(value);
                  }}
                  opList={unitTypeList}
                  placeholder="Select Type"
                  selectedOp={type}
                ></CustomSelect>
              </div>
              <div className="column is-6">
                <CutomInput
                  label="Address"
                  onChange={($event) => {
                    setaddress($event.target.value);
                  }}
                  value={address}
                ></CutomInput>

                <div className="has-text-centered">
                  <CustomButton
                    lable="Update"
                    onClick={updateUser}
                  ></CustomButton>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditUnit;
