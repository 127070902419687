import { FunctionComponent, useEffect } from "react";
import SinginForm from "./components/SinginForm";
import { useHistory } from "react-router-dom";

import './signup.css';

interface SignupProps {

}

const Signup: FunctionComponent<SignupProps> = () => {
    let history = useHistory();

    useEffect(() => {
        if (localStorage.getItem('userId') === 'admin') {
            history.push("/dashboad");
        } else if (localStorage.getItem('userId')) {
            history.push("/assist");
        }
    }, [history])

    return (<div className="section is-medium " style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + '/dnd-login-bg.png'}) `, backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
        marginTop: '-52px'

    }} >
        <div className="container">
            <div className="column is-4 is-offset-7">
                <div className="box p-6">
                    <img src={process.env.PUBLIC_URL + '/logo.png'} alt="logo" />
                    <p className="is-size-6 mt-4 mb-4 has-text-centered">
                        Login with your account credentials
                    </p>
                    <SinginForm></SinginForm>
                </div>
            </div>
        </div>
    </div>);
}

export default Signup;