import { FunctionComponent, useEffect, useState } from "react";

import module from "../../../interfaces/module";
import { getCall } from "../../../services/api_service";
import PerformanceStander from "../../../interfaces/performanceStander";
import CustomSelect from "../../../components/CustomSelect";
import { useLocation } from "react-router-dom";

interface HeaderProps {
  elementList: any;
  setelementList: Function;
}

const Header: FunctionComponent<HeaderProps> = ({
  elementList,
  setelementList,
}) => {
  const [moduleList, setmoduleList] = useState<Array<module>>([]);
  const [subModuleList, setsubModuleList] = useState<Array<module>>([]);
  const [psList, setpsList] = useState<Array<PerformanceStander>>([]);

  const [selectedModule, setSelectedModule] = useState<any>("");
  const [selectedSubModule, setSelectedSubModule] = useState<any>("");
  const [selectedPs, setSelectedPs] = useState<any>("");

  const location = useLocation();
  const isApproved = !!location["search"].includes("?isApproved=true");

  const [originalList, setOriginalList] = useState<any>([]);


  useEffect(() => {
    getElements();
  }, []);

  const getModule = (data: any) => {
    const modList = data.map((it: any) => ({
      id: it.moduleid,
      name: it.modulename,
      superModule: "null",
    }));
    setmoduleList(
      modList.filter(
        (v: any, i: any, a: any) =>
          a.findIndex((v2: any) => v2.id === v.id) === i
      )
    );
  };

  const getSubModule = (moduleId: string) => {

    const modList = originalList
      .filter((it: any) => it.moduleid == moduleId)
      .map((it: any) => ({
        id: it.submoduleid,
        name: it.submodulename,
        superModule: it.parentmodule,
      }));

    setsubModuleList(
      modList.filter(
        (v: any, i: any, a: any) =>
          a.findIndex((v2: any) => v2.id === v.id) === i
      )
    );
  };

  const getps = (moduleId: string) => {
    const modList = originalList
      .filter((it: any) => it.submoduleid == moduleId)
      .map((it: any) => ({
        id: it.performancestandardid,
        name: it.performancestandardname,
      }));

    setpsList(
      modList.filter(
        (v: any, i: any, a: any) =>
          a.findIndex((v2: any) => v2.id === v.id) === i
      )
    );
  };

  const getElements = () => {
    const currentUserId = localStorage.getItem("userId") || "NA";
    const isAuditor =
      localStorage.getItem("userRole") === "Auditor" ? true : false;
    getCall(
      "/element/" +
        (isAuditor ? "Auditor" : currentUserId) +
        "/" +
        isApproved
    ).then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      console.log("elements", data);
      setOriginalList(data);

      setelementList(data);
      getModule(data);
    });
  };

  const handleModuleChange = (value: any) => {
    console.log("selected module", value, originalList);

    setSelectedModule(value);

    // reset data
    setsubModuleList([]);
    setpsList([]);
    setSelectedSubModule("");
    setSelectedPs("");
    getSubModule(value.id);
    const modList = originalList.filter((it: any) => it.moduleid == value.id);
    console.log("select module element", modList, originalList);
    
    setelementList(modList);
  };

  const handleSubModuleChange = (value: any) => {
    setSelectedSubModule(value);

    // reset data
    setpsList([]);
    setSelectedPs("");

    getps(value.id);
    if (value.id == "all") {
      const modList = originalList.filter(
        (it: any) => it.moduleid == selectedModule.id
      );
      setelementList(modList);
    } else {
      const modList = originalList.filter(
        (it: any) => it.submoduleid == value.id
      );
      setelementList(modList);
    }
  };

  const handlePSChange = (value: any) => {
    setSelectedPs(value);

    if (value.id === "all") {
      if (selectedSubModule.id === "all") {
        const modList = originalList.filter(
          (it: any) => it.moduleid == selectedModule.id
        );
        setelementList(modList);
      } else{
        const modList = originalList.filter(
          (it: any) => it.submoduleid == selectedSubModule.id
        );
        setelementList(modList);
      }
    } else {
      const modList = originalList.filter(
        (it: any) => it.performancestandardid == value.id
      );
      setelementList(modList);
    }
    // reset data
    // getElements(value.id);
  };

  return (
    <div className="columns">
      <div className="column is-3 ">
        <CustomSelect
          label="Module Name"
          placeholder="Select Module"
          selectedOp={selectedModule}
          handleOpChange={handleModuleChange}
          opList={moduleList}
        ></CustomSelect>
      </div>
      <div className="column is-3">
        <CustomSelect
          label="Sub-Module Name"
          placeholder="Select Sub-Module"
          selectedOp={selectedSubModule}
          handleOpChange={handleSubModuleChange}
          opList={subModuleList}
          hasAllOp={subModuleList.length === 0}
        ></CustomSelect>
      </div>
      <div className="column is-6">
        <CustomSelect
          label="Performance Standard Name"
          placeholder="Select Performance Standard"
          selectedOp={selectedPs}
          handleOpChange={handlePSChange}
          opList={psList}
          hasAllOp={true}
        ></CustomSelect>
      </div>
    </div>
  );
};

export default Header;
