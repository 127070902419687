import { FunctionComponent, useState } from "react";
import { readFile } from "../../../../utils/excelToJson";
import { SiMicrosoftexcel } from "react-icons/si";
interface ExcelToJsonProps {
  setxlData: Function;
}

const ExcelToJson: FunctionComponent<ExcelToJsonProps> = ({ setxlData }) => {
  const [file, setfile] = useState<any>();

  const filePathset = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setfile(e.target.files[0]);
    const xlData = await readFile(e.target.files[0], ["name", "email", "role"]);
    console.log(xlData);
    setxlData(xlData);
  };

  return (
    <div className="form-group">
      <div className="file has-name is-fullwidth">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="ps"
            onChange={filePathset}
          />
          <span className="file-cta">
            <span className="file-icon">
              <SiMicrosoftexcel></SiMicrosoftexcel>
            </span>
            <span className="file-label">Select Excel File</span>
          </span>
          <span className="file-name">{file?.name}</span>
        </label>
      </div>
    </div>
  );
};

export default ExcelToJson;
