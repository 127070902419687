import { MultiSelect } from "primereact/multiselect";
import { FunctionComponent, useEffect, useState } from "react";
import { CustomButton } from "../../../../components/CustomButton";
import CutomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import module from "../../../../interfaces/module";
import { putCall } from "../../../../services/api_service";
import { entityTypeList, roleList } from "../../../../utils/utils";

interface EditUserProps {
  getUsers: Function;
  selectedEditUser: any;
  setselectedEditUser: Function;
  moduleList: Array<module>;
}

const EditEntity: FunctionComponent<EditUserProps> = ({
  selectedEditUser,
  getUsers,
  setselectedEditUser,
  moduleList,
}) => {
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");

  const [type, settype] = useState<any>({});

  const [address, setaddress] = useState("");

  const [selectedModule, setSelectedModule] = useState([]);

  useEffect(() => {
    setname(selectedEditUser.name);
    setdescription(selectedEditUser.description);
    setaddress(selectedEditUser.address);
    settype({ name: selectedEditUser.type, id: selectedEditUser.type });
  }, [selectedEditUser]);

  const updateUser = () => {
    if (name !== "") {
      putCall("/users/entity/" + selectedEditUser.id, {
        name,
        description,
        type:type.name,
        address,
        selectedModules: JSON.stringify(selectedModule),
      }).then(() => {
        getUsers();
        setselectedEditUser(null);
      });
    }
  };

  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setselectedEditUser(null);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Edit Entity</p>
            </div>
            <br />
            <div className="columns is-multiline">
              <div className="column is-6">
                <CutomInput
                  label="Name"
                  onChange={($event) => {
                    setname($event.target.value);
                  }}
                  value={name}
                ></CutomInput>
              </div>
              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    settype(value);
                  }}
                  opList={entityTypeList}
                  placeholder="Select Type"
                  selectedOp={type}
                ></CustomSelect>
              </div>
              <div className="column is-6">
                <CutomInput
                  label="Address"
                  onChange={($event) => {
                    setaddress($event.target.value);
                  }}
                  value={address}
                ></CutomInput>
              </div>
              {moduleList.length && (
                <div
                  className="column is-12"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <label>Assign Modules</label>
                  <MultiSelect
                    value={selectedModule}
                    options={moduleList}
                    optionValue="id"
                    optionLabel="name"
                    onChange={(e) => setSelectedModule(e.value)}
                    display="chip"
                  />
                </div>
              )}
              <div className="has-text-centered">
                <CustomButton
                  lable="Update"
                  onClick={updateUser}
                ></CustomButton>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default EditEntity;
