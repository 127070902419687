import { Select } from "antd";
import React from "react";
const { Option } = Select;

const SelectInput = ({
  placeholder,
  label = "",
  options = [],
  value,
  onChange = () => {},
  marginTopValue = "41px",
  hasObjectOptions = false,
}) => {
  return (
    <div
      style={{
        marginTop: marginTopValue,
        width: "100%",
        fontSize: "16px",
        fontWeight: "bold",
      }}
    >
      <label> {label} </label> <br />
      <Select
        onChange={onChange}
        value={value || undefined}
        name={label}
        placeholder={placeholder}
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        filterSort={(optionA, optionB) =>
          optionA.children
            .toLowerCase()
            .localeCompare(optionB.children.toLowerCase())
        }
      >
        {options.map((option) =>
          hasObjectOptions ? (
            <Option key={option.id} value={option.id}>
              {option.name}
            </Option>
          ) : (
            <Option key={option} value={option}>
              {option}
            </Option>
          )
        )}
      </Select>
    </div>
  );
};

export default SelectInput;
