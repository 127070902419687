import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { getCall, putCall } from "../../../services/api_service";
import {
  CustomButton,
  CustomIconButton,
} from "../../../components/CustomButton";
import { useLocation } from "react-router-dom";

interface AssistTableProps {
  elementList: any;
  setSelectedElement: Function;
}

const AssistTable: FunctionComponent<AssistTableProps> = ({
  elementList,
  setSelectedElement,
}) => {
  const location = useLocation();
  const isApproved = location["search"].includes("?isApproved=true");
  const [elements, setElements] = useState<any>([]);
  const toast = useRef<any>(null);

  const currentUserRole = localStorage.getItem("userRole") || "";

  useEffect(() => {
    setElements(elementList);
  }, [elementList]);

  const updateElement = (rowData: any, isAuditorApproved: boolean = false) => {
    const isAuditor = currentUserRole === "Auditor" ? true : false;
    putCall("/element", {
      ...rowData,
      auditStatus: isAuditor
        ? isAuditorApproved
          ? "APPROVED"
          : "PENDING_USER"
        : "PENDING_AUDITOR",
    }).then((data) => {
      toast.current.show({
        severity: "success",
        summary: "",
        detail: "Added Successfully",
        life: 3000,
      });
      getElements()
    });
  };

  const getElements = () => {
    const currentUserId = localStorage.getItem("userId") || "NA";
    const isAuditor =
      localStorage.getItem("userRole") === "Auditor" ? true : false;
    getCall(
      "/element/" + (isAuditor ? "Auditor" : currentUserId) + "/" + isApproved
    ).then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      console.log("elements", data);
      setElements(data);

      setElements(data);
    });
  };

  const currentStatuses = [
    { label: "Well Managed", value: "Well Managed" },
    { label: "Satisfactory", value: "Satisfactory" },
    {
      label: "Some Improvement Opportunity",
      value: "Some Improvement Opportunity",
    },
    {
      label: "Major Improvement Opportunity",
      value: "Major Improvement Opportunity",
    },
    {
      label: "Unacceptable Risk Exposure",
      value: "Unacceptable Risk Exposure",
    },
  ];
  const risk = [
    { label: "Unrated", value: "Unrated" },
    { label: "Low Risk", value: "Low Risk" },
    { label: "Moderate Risk", value: "Moderate Risk" },
    { label: "High Risk", value: "High Risk" },
    { label: "Extreme Risk", value: "Extreme Risk" },
  ];

  const onEditorValueChange = (props: any, value: any) => {
    let updatedElements = [...props.value];
    updatedElements[props.rowIndex][props.field] = value;
    setElements(updatedElements);
  };

  const inputTextEditor = (props: any, field: any) => {
    return (
      <InputText
        type="text"
        value={props.rowData[field]}
        className="p-inputtext-sm"
        onChange={(e) => onEditorValueChange(props, e.target.value)}
      />
    );
  };

  const statusEditor = (props: any) => {
    return (
      <Dropdown
        value={props.rowData["status"]}
        options={currentStatuses}
        optionLabel="label"
        optionValue="value"
        onChange={(e) => onEditorValueChange(props, e.value)}
        style={{ width: "100%" }}
        placeholder="Status"
        itemTemplate={(option) => {
          return (
            <span
              className={`product-badge status-${option.value.toLowerCase()}`}
            >
              {option.label}
            </span>
          );
        }}
      />
    );
  };

  const riskEditor = (props: any) => {
    return (
      <Dropdown
        value={props.rowData["risk"]}
        options={risk}
        optionLabel="label"
        optionValue="value"
        onChange={(e) => onEditorValueChange(props, e.value)}
        style={{ width: "100%" }}
        placeholder="Status"
        itemTemplate={(option) => {
          return (
            <span
              className={`product-badge status-${option.value.toLowerCase()}`}
            >
              {option.label}
            </span>
          );
        }}
      />
    );
  };

  const disclouserEditor = (props: any) => {
    return inputTextEditor(props, "disclosures");
  };

  const followUpEditor = (props: any) => {
    return (
      <InputText
        type="date"
        value={props.rowData["followup"]}
        className="p-inputtext-sm"
        onChange={(e) => onEditorValueChange(props, e.target.value)}
      />
    );
  };

  const commentsEditor = (props: any) => {
    return inputTextEditor(props, "comments");
  };

  const remarksEditor = (props: any) => {
    if (currentUserRole === "Auditor") {
      return inputTextEditor(props, "remarks");
    } else {
      return props.rowData["remarks"];
    }
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <Dropdown
        value={rowData["status"]}
        options={currentStatuses}
        optionLabel="label"
        optionValue="value"
        onChange={(e) => {}}
        style={{ width: "100%" }}
        placeholder="Status"
        itemTemplate={(option) => {
          return (
            <span
              className={`product-badge status-${option.value.toLowerCase()}`}
            >
              {option.label}
            </span>
          );
        }}
      />
    );
  };

  const riskBodyTemplate = (rowData: any) => {
    return (
      <Dropdown
        value={rowData["risk"]}
        options={risk}
        optionLabel="label"
        optionValue="value"
        onChange={(e) => onEditorValueChange(rowData, e.value)}
        style={{ width: "100%" }}
        placeholder="Status"
        itemTemplate={(option) => {
          return (
            <span
              className={`product-badge status-${option.value.toLowerCase()}`}
            >
              {option.label}
            </span>
          );
        }}
      />
    );
  };
  const disclouserBodyTemplate = (rowData: any) => {
    return (
      <InputText
        type="text"
        value={rowData.disclosures}
        className="p-inputtext-sm"
      />
    );
  };

  const attachmentBodyTemplate = (rowData: any) => {
    return (
      <CustomIconButton
        icon="atached.svg"
        onClick={(event: any) => {
          event.preventDefault();
          setSelectedElement(rowData);
        }}
      ></CustomIconButton>
    );
  };

  const commentsBodyTemplate = (rowData: any) => {
    return (
      <InputText
        type="text"
        value={rowData.comments}
        className="p-inputtext-sm"
      />
    );
  };

  const followUpTemplate = (rowData: any) => {
    const date = new Date(rowData["followup"]) ?? Date.now();
    const dataToShow =
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();

    return currentUserRole !== "Auditor" && !isApproved ? (
      <InputText type="date" value={dataToShow} className="p-inputtext-sm" />
    ) : (
      dataToShow
    );
  };

  const remarksBodyTemplate = (rowData: any) => {
    return (
      <InputText
        type="text"
        value={rowData.remarks}
        className="p-inputtext-sm"
        placeholder="NA"
      />
    );
  };

  const headerTemplate = (data: any) => {
    return (
      <div>
        {" "}
        <br />
        <span className="has-text-weight-bold mt-2 ">
          {" "}
          {data.index}. {data.name}
        </span>
      </div>
    );
  };

  const footerTemplate = (data: any) => {
    return (
      <div>
        {/* <td colSpan={4} style={{ textAlign: 'right' }}>Total Customers</td>
                <td>{data.name}</td> */}
      </div>
    );
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div style={{display:'flex', justifyContent:'Center'}}>
        {!isApproved && (
          <CustomButton
            lable={currentUserRole === "Auditor"? "Reject":"Save"}
            onClick={() => {
              updateElement(rowData, false);
            }}
          ></CustomButton>
        )}
        &nbsp;
        {currentUserRole === "Auditor" && (
          <CustomButton
            lable="Approve"
            isSecoundry={true}
            onClick={() => {
              updateElement(rowData, true);
            }}
          ></CustomButton>
        )}
      </div>
    );
  };

  return (
    <div className="p-4">
      <DataTable
        value={elements}
        dataKey="element"
        groupField="name"
        rowGroupMode="subheader"
        rowGroupHeaderTemplate={headerTemplate}
        rowGroupFooterTemplate={footerTemplate}
      >
        <Column headerStyle={{ width: "3rem" }}></Column>

        <Column
          field="status"
          headerStyle={{ width: "10rem" }}
          header="Current Status"
          body={
            currentUserRole !== "Auditor" && !isApproved
              ? statusBodyTemplate
              : null
          }
          editor={(props) => statusEditor(props)}
        ></Column>
        <Column
          field="risk"
          headerStyle={{ width: "10rem" }}
          header="Risk"
          body={
            currentUserRole !== "Auditor" && !isApproved
              ? riskBodyTemplate
              : null
          }
          editor={(props) => riskEditor(props)}
        ></Column>

        <Column
          field="disclosures"
          headerStyle={{ width: "12rem" }}
          header="Disclosures"
          body={
            currentUserRole !== "Auditor" && !isApproved
              ? disclouserBodyTemplate
              : null
          }
          editor={(props) => disclouserEditor(props)}
        ></Column>

        <Column
          field="comments"
          headerStyle={{ width: "12rem" }}
          header="Comments/Way Forward"
          body={
            currentUserRole !== "Auditor" && !isApproved
              ? commentsBodyTemplate
              : null
          }
          editor={(props) => commentsEditor(props)}
        ></Column>
        <Column
          field="followup"
          headerStyle={{ width: "11rem" }}
          header="Followup"
          body={followUpTemplate}
          editor={(props) => followUpEditor(props)}
        ></Column>
        <Column
          field="remarks"
          headerStyle={{ width: "11rem" }}
          header="Assessment Remark "
          body={
            currentUserRole === "Auditor" && !isApproved
              ? remarksBodyTemplate
              : null
          }
          editor={(props) => remarksEditor(props)}
        ></Column>
        <Column
          field="attachments"
          headerStyle={{ width: "7rem" }}
          style={{ textAlign: "center" }}
          header="Attachments"
          body={attachmentBodyTemplate}
        ></Column>
        <Column
          body={actionBodyTemplate}
          header="Actions"
          style={{ textAlign: "center", width: "12em" }}
        ></Column>
      </DataTable>

      <Toast ref={toast} />
    </div>
  );
};

export default AssistTable;
