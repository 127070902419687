import { FunctionComponent, useState } from "react";
import FormControl from "@mui/material/FormControl";

import { CustomButton } from "../../../components/CustomButton";
import CutomInput from "../../../components/CustomInput";
import { TextField } from "@mui/material";
import { postCall } from "../../../services/api_service";

interface SinginFormProps {}

const SinginForm: FunctionComponent<SinginFormProps> = () => {
  const [values, setValues] = useState<any>({
    email: "",
    password: "",
  });

  const [errorMsg, setErrorMsg] = useState(false);

  const handleChange =
    (prop: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const login = () => {
    // if (values.email === "admin@xyz.com" && values.password === "admin") {
    //   localStorage.setItem("userId", "admin");
    //   window.location.reload();
    // } else {
    postCall("/users/login", {
      email: values.email,
      password: values.password,
    })
      .then((data) => {
        if (data.length > 0) {
          localStorage.setItem("userId", data[0]?.id);
          localStorage.setItem("userRole", data[0]?.role);
          localStorage.setItem("name", data[0]?.name);
          window.location.reload();
        } else {
          setErrorMsg(true);
        }
      })
      .catch((e) => {
        console.log(e);
      });
    // }
  };

  return (
    <div>
      {errorMsg ? (
        <article className="message is-danger">
          <div className="message-body">Invalid Email Or Password</div>
        </article>
      ) : (
        ""
      )}
      <form
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <CutomInput
          label="E-Mail"
          onChange={handleChange("email")}
        ></CutomInput>
        <br /> <br />
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <TextField
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            onChange={handleChange("password")}
            fullWidth={true}
            label="Password"
            variant="standard"
          />
        </FormControl>
        <br /> <br />
        <CustomButton
          lable={"Login"}
          onClick={login}
          isFull={true}
          isSecoundry={true}
        ></CustomButton>
      </form>
    </div>
  );
};

export default SinginForm;
