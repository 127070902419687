/* eslint-disable jsx-a11y/anchor-is-valid */
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../config/routes";
import ResetPassword from "../pages/resetPassword";

interface TopNavProps {
  setUserType: Function;
  drowerOpen: boolean;
}

const TopNav: FunctionComponent<TopNavProps> = ({
  setUserType,
  drowerOpen,
}) => {
  let history = useHistory();
  const [pageTitle, setpageTitle] = useState("");
  const location = useLocation();
  const [resetPassword, setResetPassword] = useState(false);

  useEffect(() => {
    const foundRoute = routes.find((route) => route.path === location.pathname);
    if (foundRoute) {
      setpageTitle(foundRoute.name);
    } else {
      setpageTitle("");
    }
  }, [location.pathname]);

  const logout = () => {
    localStorage.clear();
    setUserType("");
    history.push("/");
  };

  return (
    <nav
      className="navbar  is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      style={{ height: "4.15em" }}
    >
      <div
        className="navbar-brand"
        style={{ marginLeft: drowerOpen ? "260px" : "70px" }}
      >
        <div className="navbar-item">
          <p className="has-text-weight-bold">{pageTitle}</p>
        </div>
      </div>

      <div id="navbarBasicExample" className="navbar-menu">
        <div className="navbar-end">
          {/* <Link to="/" className="navbar-item">
                        Home
                    </Link> */}

          {/* <a className="navbar-item">
                        Documentation
                    </a> */}
          <div className="navbar-item">
            &nbsp; &nbsp;
            <div className="buttons">
              <a
                className="button is-light"
                href="https://drive.google.com/file/d/1mtckdmB-LdPtUZ9Zkirp2cw4hvHt4TP2/view?usp=sharing"
                target={"_blank"}
              >
                User Manual
              </a>
            </div>
          </div>

          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
              Welcome, {localStorage.getItem("name") || "user"}
            </a>

            <div className="navbar-dropdown">
              <a
                href={process.env.REACT_APP_CRM_URL}
                target={"_blank"}
                style={{ marginLeft: "15px" }}
              >
                CRM
              </a>
              &nbsp; &nbsp;
              <br></br>
              <a
                href={process.env.REACT_APP_LMS_URL}
                target={"_blank"}
                style={{ marginLeft: "15px" }}
              >
                LMS
              </a>
              <br></br>
              <a
                style={{ marginLeft: "15px" }}
                onClick={() => setResetPassword(true)}
              >
                Reset Password
              </a>
              <br></br>
              <a style={{ marginLeft: "15px" }} onClick={logout}>
                Log out
              </a>
            </div>
          </div>
        </div>
      </div>
      <ResetPassword reset={resetPassword} setReset={setResetPassword} />
    </nav>
  );
};

export default TopNav;
