import { FunctionComponent, useState } from "react";
import Header from "./components/AssistHeader";
import AssistTable from "./components/AssistTable";
import "./assist.css";
import AssistAttachmentsModel from "./components/AssistAttachements";

interface AssistElementProps {}

const AssistElement: FunctionComponent<AssistElementProps> = () => {
  const [elementList, setelementList] = useState<Array<any>>([]);
  const [selectedElement, setSelectedElement] = useState<any>(null);

  return (
    <div
      className="section container"
      style={{ marginLeft: "10px", maxWidth: "80%" }}
    >
      {selectedElement && (
        <AssistAttachmentsModel
          selectedElement={selectedElement}
          setSelectedElement={setSelectedElement}
        />
      )}
      <div className=" box">
        <Header
          elementList={elementList}
          setelementList={setelementList}
        ></Header>
        <div>
          <AssistTable
            elementList={elementList}
            setSelectedElement={setSelectedElement}
          ></AssistTable>
        </div>
      </div>
    </div>
  );
};

export default AssistElement;
