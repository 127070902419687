import React, { useEffect, useState } from "react";
import "./App.css";
import SideNav from "./components/sideNav";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Application: React.FunctionComponent<{}> = (props) => {
  const [userType, setUserType] = useState("");

  useEffect(() => {
    if (localStorage.getItem("userRole") === "admin") {
      setUserType("admin");
    } else if (localStorage.getItem("userRole") === "L3") {
      setUserType("entity");
    } else if (localStorage.getItem("userId")) {
      setUserType("normal");
    }

    // //@ts-ignore
    // const initialValue = document.body.style.zoom;
    //@ts-ignore
    document.body.style.zoom = "90%";
  }, []);

  return (
    <div>
      <SideNav setUserType={setUserType} userType={userType}></SideNav>
      <ToastContainer />
    </div>
  );
};

export default Application;
