import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Application from "./application";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import "./utils/jwt.interceptor";

// Or Create your Own theme:
const theme = createTheme({
  palette: {
    primary: {
      main: "#ff8f00", // This is an orange looking color
    },
    secondary: {
      main: "#ffcc80", //Another orange-ish color
    },
  },
});
ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <Application />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
