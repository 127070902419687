import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";

import TopNav from "./TopNav";

import {
  BrowserRouter,
  Route,
  Switch,
  RouteComponentProps,
  Link,
} from "react-router-dom";
import routes from "../config/routes";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const adminMenu = [
  { icon: "home.svg", label: "Home", link: "/dashboard" },
  { icon: "module.svg", label: "Module", link: "/module" },
  {
    icon: "ps.svg",
    label: "Performance Standards",
    link: "/Performance-standards",
  },
  { icon: "elements.svg", label: "Elements", link: "/elements" },
  { icon: "users.svg", label: "Master", link: "/master" },
  { icon: "users.svg", label: "Directory", link: "/directory" },
  { icon: "users.svg", label: "Document Library", link: "/docs-lib" },
];

const userMenu = [
  { icon: "home.svg", label: "Dashboard", link: "/dashboard" },
  { icon: "home.svg", label: "Home", link: "/" },
];
const unitMenu = [
  { icon: "home.svg", label: "Dashboard", link: "/dashboard" },
  { icon: "home.svg", label: "Home", link: "/" },
  { icon: "elements.svg", label: "Elements", link: "/elements" },
  { icon: "users.svg", label: "Master", link: "/master" },
  { icon: "users.svg", label: "Directory", link: "/directory" },
  { icon: "users.svg", label: "Document Library", link: "/docs-lib" },
];

const currentUserRole = localStorage.getItem("userRole") || "";
if (currentUserRole !== "Auditor") {
  userMenu.push(
    ...[
      {
        icon: "elements.svg",
        label: "Reports",
        link: "/assist?isApproved=true",
      },
      { icon: "elements.svg", label: "Elements", link: "/elements" },
      { icon: "users.svg", label: "Directory", link: "/directory" },
      { icon: "users.svg", label: "Document Library", link: "/docs-lib" },
    ]
  );
  unitMenu.push({
    icon: "elements.svg",
    label: "Reports",
    link: "/assist?isApproved=true",
  });
}

interface SidenavProps {
  setUserType: Function;
  userType: string;
}

const MiniDrawer: React.FunctionComponent<SidenavProps> = ({
  userType,
  setUserType,
}) => {
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <BrowserRouter>
        {userType ? (
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              {open ? (
                <div style={{ display: "flex" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/logo.png"}
                    alt="logo"
                    style={{ width: "80%" }}
                  />
                  <IconButton onClick={handleDrawerClose}>
                    <ChevronLeftIcon />
                  </IconButton>
                </div>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                >
                  <MenuIcon />
                </IconButton>
              )}
            </DrawerHeader>
            <Divider />
            <List
              style={{
                height: "100vh",
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + "/sidenav-bg.png"
                }) `,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              {userType === "admin"
                ? adminMenu.map((menu, index) => (
                    <Link to={menu.link}>
                      {" "}
                      <ListItem button key={index} style={{ color: "#fff" }}>
                        <ListItemIcon>
                          <img
                            src={process.env.PUBLIC_URL + "/icons/" + menu.icon}
                            alt="iconName"
                            style={{ height: "25px" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={menu.label} />
                      </ListItem>
                    </Link>
                  ))
                : userType === "entity"?
                unitMenu.map((menu, index)=>(
                    <Link to={menu.link}>
                      {" "}
                      <ListItem button key={index} style={{ color: "#fff" }}>
                        <ListItemIcon>
                          <img
                            src={process.env.PUBLIC_URL + "/icons/" + menu.icon}
                            alt="iconName"
                            style={{ height: "25px" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={menu.label} />
                      </ListItem>
                    </Link>
                  )):
                userMenu.map((menu, index) => (
                    <Link to={menu.link}>
                      {" "}
                      <ListItem button key={index} style={{ color: "#fff" }}>
                        <ListItemIcon>
                          <img
                            src={process.env.PUBLIC_URL + "/icons/" + menu.icon}
                            alt="iconName"
                            style={{ height: "25px" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={menu.label} />
                      </ListItem>
                    </Link>
                  ))}
            </List>
          </Drawer>
        ) : (
          ""
        )}
        <Box component="main" sx={{ flexGrow: 1 }}>
          {/* <DrawerHeader /> */}
          {userType ? (
            <TopNav drowerOpen={open} setUserType={setUserType}></TopNav>
          ) : (
            ""
          )}

          <Switch>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  render={(props: RouteComponentProps<any>) => (
                    <route.component
                      name={route.name}
                      {...props}
                      {...route.props}
                    />
                  )}
                />
              );
            })}
          </Switch>
        </Box>
      </BrowserRouter>
    </Box>
  );
};

export default MiniDrawer;
