import { FunctionComponent, useEffect, useState, useRef } from "react";
import { getCall, postCall } from "../../../../services/api_service";

import ExcelToJson from "./ExcelToJson";
import CustomSelect from "../../../../components/CustomSelect";
import CutomInput from "../../../../components/CustomInput";
import { CustomButton } from "../../../../components/CustomButton";
import { toast } from "react-toastify";

interface CreateElementModelProps {
  setShowCreateElement: Function;
  handleModuleChange: Function;
  moduleList: Array<any>;
  subModuleList: Array<any>;
  selectedModule: any;
  selectedSubModule: any;
  selectedPs: any;
  handlePSChange: Function;
  handleSubModuleChange: Function;
  psList: Array<any>;
  getElements: Function;
  showCreateElement: boolean;
}

const CreateElementModel: FunctionComponent<CreateElementModelProps> = ({
  setShowCreateElement,
  showCreateElement,
  handleModuleChange,
  moduleList,
  selectedModule,
  subModuleList,
  selectedSubModule,
  handlePSChange,
  selectedPs,
  handleSubModuleChange,
  psList,
  getElements,
}) => {
  const [name, setname] = useState("");

  const [userList, setUserList] = useState<Array<any>>([]);
  const [xlData, setxlData] = useState<Array<any>>([]);
  const [selectedUser, setSelectedUser] = useState<any>("");
  const [notAddedData, setNotAddedData] = useState<Array<any>>([]);
  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    getCall("/users").then((data) => {
      setUserList(data);
    });
  };

  const handleUserChange = (value: any) => {
    setSelectedUser(value);
  };

  const addElement = () => {
    console.log("xldata", xlData);

    if (name !== "" && selectedPs !== "") {
      postCall("/element", {
        name,
        performanceStandardId: selectedPs.id,
        assignedUserId: selectedUser.id,
      }).then((res) => {
        console.log("res", res);
        
        getElements();
        // toast("Added Successfully");

        setShowCreateElement(false);
      });
    } else if (xlData.length > 0) {
      postCall("/element/addBulk", { elements: xlData })
        .then((data) => {
          setNotAddedData(data.notAdded);
          getElements();
          // toast("Added Successfully");

          setShowCreateElement(false);
        })
        .catch((e) => {
          toast.error("Couldn't add the element");
        });
    }
  };

  return (
    <div>
      <div className={showCreateElement ? "is-active modal" : "modal"}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setShowCreateElement(false);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Add Elements</p>
            </div>
            <br />

            <div className="columns is-multiline">
              <div className="column is-6">
                <CustomSelect
                  label="Module Name"
                  placeholder="Select Module"
                  selectedOp={selectedModule}
                  handleOpChange={handleModuleChange}
                  opList={moduleList}
                ></CustomSelect>
              </div>
              <div className="column is-6">
                <CustomSelect
                  label="Sub-Module Name"
                  placeholder="Select Sub-Module"
                  selectedOp={selectedSubModule}
                  handleOpChange={handleSubModuleChange}
                  opList={subModuleList}
                ></CustomSelect>
              </div>
              <div className="column is-12">
                <CustomSelect
                  label="Performance Standard Name"
                  placeholder="Select Performance Standard"
                  selectedOp={selectedPs}
                  handleOpChange={handlePSChange}
                  opList={psList}
                ></CustomSelect>
              </div>
              <div className="column is-12">
                <CutomInput
                  label="Element"
                  onChange={($event) => {
                    setname($event.target.value);
                  }}
                  value={name}
                ></CutomInput>
              </div>
              <div className="column is-6">
                <CustomSelect
                  label="Assign To"
                  placeholder="Select user"
                  selectedOp={selectedUser}
                  handleOpChange={handleUserChange}
                  opList={userList}
                ></CustomSelect>
              </div>
            </div>

            <p className="has-text-centered">or Add Bulk for Excel</p>
            <br />
            <ExcelToJson setxlData={setxlData}></ExcelToJson>
            <br />
            <div className="has-text-centered">
              <CustomButton lable="Add" onClick={addElement}></CustomButton>
            </div>
          </section>
        </div>
      </div>

      {notAddedData && notAddedData.length > 0 && (
        <div className="box mb-4">
          <strong className="mb-2 has-text-danger">
            Elements that was not added.
          </strong>
          {notAddedData.map((el) => (
            <p> {el.element} </p>
          ))}
        </div>
      )}

      {/* <div className="box">
                <header className="mb-4">
                    <p className="title">Add Element</p>
                </header>

                <div className="columns"  >
                    <div className="column">
                        <FormControl fullWidth>
                            <InputLabel id="module-label">Select Module</InputLabel>
                            <Select
                                labelId="module-label"
                                id="module"
                                size="small"
                                value={selectedModule}
                                label="Current Status"
                                onChange={handleModuleChange}
                            >
                                {moduleList.map(module => <MenuItem key={module.id} value={module.id}>{module.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    {subModuleList.length > 0 ? <div className="column"  >
                        <FormControl fullWidth>
                            <InputLabel id="sub-module-label">Select Sub Module</InputLabel>
                            <Select
                                labelId="sub-module-label"
                                id="sub-module"
                                size="small"
                                value={selectedSubModule}
                                label="Current Status"
                                onChange={handleSubModuleChange}
                            >
                                {subModuleList.map(module => <MenuItem key={module.id} value={module.id}>{module.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div> : ''}



                    {psList.length > 0 ? <div className="column pb-4 pt-4"  >
                        <FormControl fullWidth>
                            <InputLabel id="sub-module-label">Select Performance Standards </InputLabel>
                            <Select
                                labelId="sub-module-label"
                                id="sub-module"
                                value={selectedPs}
                                size="small"
                                label="Current Status"
                                onChange={handlePSChange}
                            >
                                {psList.map(ps => <MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div> : ''}
                </div>
                {ShowInput ?
                    <div className="columns" >

                        <div className="column is-8">
                            <TextField id="element-name" value={name} onChange={($event) => { setname($event.target.value) }} style={{ width: '100%' }} label="Element" variant="outlined" />
                        </div>

                        <div className="column is-4" >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select User</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedUser}
                                    label="Assing to"
                                    onChange={handleUserChange}
                                >
                                    {userList.map(user => <MenuItem key={user.id} value={user.id}>{`${user.name}  |  ${user.email}  |  ${user.role} `}  </MenuItem>)}
                                </Select>
                            </FormControl>
                        </div>
                       


                    </div> : ''}
                <HrText text={'or Add Bulk for Excel'} ></HrText>
                <ExcelToJson setxlData={setxlData} ></ExcelToJson>
                {xlData.length > 0 ? <ExDataTable data={xlData} > </ExDataTable> : ''}

                <br />
                <button className="button is-link" onClick={addElement} >Add</button>

            </div> */}
    </div>
  );
};

export default CreateElementModel;
