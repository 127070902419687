import { FunctionComponent, useState } from "react";
import { putCall } from "../../../../services/api_service";
import { CustomButton } from "../../../../components/CustomButton";
import CutomInput from "../../../../components/CustomInput";

interface EditModuleProps {
    selectedModule: any,
    setSelectedModule: Function,
    getModule: Function
}

const EditModule: FunctionComponent<EditModuleProps> = ({ selectedModule, setSelectedModule, getModule }) => {

    const [name, setname] = useState(selectedModule.name);

    const update = () => {
        if (name !== '') {
            putCall('/module/' + selectedModule.id, { name }).then(() => {
                setSelectedModule(null);
                getModule();
            });
        }
    }



    return (
        <div className="modal is-active ">
            <div className="modal-background"></div>
            <div className="modal-card">
                <section className="modal-card-body">
                    <div>
                        <button className="delete" aria-label="close" style={{ float: 'right' }} onClick={() => { setSelectedModule(null) }} ></button>
                        <p className="modal-card-title">Edit Module</p>
                    </div>
                    <br />
                    <div>
                        <CutomInput label="Module Name" onChange={($event) => { setname($event.target.value) }} value={name} ></CutomInput>
                    </div>
                    {/* <TextField id="outlined-basic" value={name} onChange={($event) => { setname($event.target.value) }} style={{ width: '100%' }} label="Module Name" variant="outlined" /> */}
                    <br />

                    <div className="has-text-centered">
                        <CustomButton lable="Save" onClick={update} ></CustomButton>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default EditModule;