import { ChangeEventHandler, FunctionComponent } from "react";
import TextField from '@mui/material/TextField';

interface CutomInputProps {
    label: string,
    onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    value?: string
}

const CutomInput: FunctionComponent<CutomInputProps> = ({ label, onChange, value }) => {
    return (<TextField
        label={label}
        type="search"
        value={value}
        fullWidth={true}
        variant="standard"
        onChange={onChange}
        size='small'
    />);
}

export default CutomInput;