import { FunctionComponent, useEffect, useState } from "react";
import { getCall } from "../../../services/api_service";
import CreateModuleModel from "./components/CreateModule";
import CreateSubModuleModel from "./components/CreateSubModule";
import EditModule from "./components/EditModule";
import ModuleTable from "./components/ModuleTable";

interface ModulePageProps {}

const ModulePage: FunctionComponent<ModulePageProps> = () => {
  const [showModuleModel, setShowModuleModel] = useState(false);
  const [showSubModuleModel, setShowSubModuleModel] = useState(false);

  const [nodes, setNodes] = useState<any>([]);
  const [modules, setModules] = useState<any>([]);
  const [modulesList, setModulesList] = useState<any>([]);
  const [selectedModule, setSelectedModule] = useState<any>(null);

  useEffect(() => {
    getModule();
  }, []);

  const getModule = () => {
    getCall("/module/withSubModule").then((data) => {
      const allModule: any = [];
      setModulesList(data);
      data.forEach((module: any) => {
        allModule.push({ module: module.name, subModule: "" });
        if (module.subModule) {
          module.subModule.forEach((sm: any) => {
            allModule.push({ module: "", subModule: sm.name });
          });
        }
      });

      setModules(allModule);

      let treeData = data.map((module: any, key: number) => {
        return {
          key: key + "",
          data: { id: module.id, name: module.name, index: key + 1 },
          children: module.subModule.map((m: any, subKey: number) => {
            return {
              key: key + "-" + subKey,
              data: { id: m.id, name: m.name },
            };
          }),
        };
      });

      setNodes(treeData);
    });
  };

  return (
    <div className="section container ">
      <ModuleTable
        nodes={nodes}
        modules={modules}
        setSelectedModule={setSelectedModule}
        setShowModuleModel={setShowModuleModel}
        setShowSubModuleModel={setShowSubModuleModel}
        getModule={getModule}
      ></ModuleTable>
      <CreateModuleModel
        showModel={showModuleModel}
        setShowModel={setShowModuleModel}
        getModule={getModule}
      ></CreateModuleModel>
      <CreateSubModuleModel
        showModel={showSubModuleModel}
        modules={modulesList}
        setShowModel={setShowSubModuleModel}
        getModule={getModule}
      ></CreateSubModuleModel>
      {selectedModule ? (
        <EditModule
          selectedModule={selectedModule}
          setSelectedModule={setSelectedModule}
          getModule={getModule}
        ></EditModule>
      ) : (
        ""
      )}
    </div>
  );
};

export default ModulePage;
