import React, { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "antd";

import { DatePicker } from "antd";
import { BASE_URL, getCall } from "../../../services/api_service";
import FilterSelect from "../../../components/FilterSelect";
import CustomTable from "../../../components/forms/CustomTable";

export default () => {
  const userRole = localStorage.getItem("userRole");
  const [originData, setOriginData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const [loading, setloading] = useState(false);

  const [entityList, setEntityList] = useState([]);
  const [unitList, setUnitsList] = useState([]);

  const [selectedEntity, setSelectedEntity] = useState("");
  const [selectedUnit, setSelectedUnit] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { RangePicker } = DatePicker;

  const columns = [
    {
      title: "SR NO",
      dataIndex: "index",
    },
    {
      title: "Attachment",
      dataIndex: "attachments",
      render: (attachment) => (
        <>
          <a href={attachment} target={"_blank"}>
            {attachment}
          </a>
        </>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdat",
    },
  ];

  useEffect(() => {
    if (userRole) {
      if (userRole != "L1" && userRole != "L2") {
        getEntities();
      } else {
        getUnits();
      }
    }
  }, []);

  useEffect(() => {
    if (selectedEntity != "") {
      getUnits();
    }
  }, [selectedEntity]);

  useEffect(() => {
    if (selectedUnit != "") {
      getAttachments();
    }
  }, [selectedUnit]);

  const getEntities = () => {
    getCall("/users/entities").then((data) => {
      if (data && data.length > 0) {
        setEntityList(data);
        setSelectedUnit("")
      }
    });
  };
  const getUnits = () => {
    if (userRole) {
      if (userRole != "L1" && userRole != "L2") {
        getCall(`/users/units/${selectedEntity}`).then((data) => {
          if (data && data.length > 0) {
            setUnitsList(data);
            setSelectedUnit(data[0].id);
          }
        });
      } else {
        getCall(`/users/all-units`).then((data) => {
          if (data && data.length > 0) {
            setUnitsList(data);
            setSelectedUnit(data[0].id);
          }
        });
      }
    }
  };

  const getAttachments = () => {
    getCall(`/element/${selectedUnit}`).then((data) => {
      console.log(data);

      data = data.filter(it=> it.attachments.length> 0).map((row, index)=> ({
        index: index+1, ...row
      }) )

      console.log("data attach", data);
      setOriginData(data);
      setFilterData(data);
    });
  };

  const getUrl = (attachment) => {
    getCall(`upload/download/${attachment}`);
  };

  const clearFilter = () => {
    setFilterData([...originData]);
  };

  useEffect(() => {
    const data = originData.filter(
      (it) => it.createdat >= startDate && it.createdat <= endDate
    );
    console.log("filter dfata", data);
    setFilterData([...data]);
  }, [endDate]);

  const onChange = (date, dateString) => {
    console.log("dates", date, dateString);
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
  };

  return (
    <Card
      span={24}
      className="da-border-color-black-40 ant-card card-without-padding"
    >
      <>
        <Row
          style={{ marginTop: "20px", marginLeft: "20px" }}
          gutter={[20, 20]}
        >
          {userRole && userRole != "L1" && userRole != "L2" && (
            <Col span={5}>
              <FilterSelect
                placeholder="Select Entity"
                options={entityList}
                label="name"
                value="id"
                onChange={(selectedValue) => {
                  setSelectedEntity(selectedValue);
                }}
              />
            </Col>
          )}

          <Col span={5}>
            <FilterSelect
              placeholder="Select Unit"
              options={unitList}
              label="name"
              value="id"
              selectedValue={selectedUnit}
              onChange={(selectedValue) => {
                setSelectedUnit(selectedValue);
              }}
            />
          </Col>

          <Col>
            <Button type="secoundry" onClick={() => getAttachments()}>
              Search
            </Button>
          </Col>
        </Row>
        <Row
          style={{ marginTop: "20px", marginLeft: "20px" }}
          gutter={[20, 20]}
        >
          <Col>
            <RangePicker onChange={onChange} />
          </Col>
          <Col>
            <Button type="secoundry" onClick={() => clearFilter()}>
              Clear
            </Button>
          </Col>
        </Row>
        <br />
        <div style={{ overflowY: "auto" }}>
          <CustomTable
            loading={loading}
            columns={columns}
            dataSource={filterData}
          />
        </div>
      </>
    </Card>
  );
};
