import React from "react";
// import EditableRowsTable from "../../components/data-display/table/editableRows";
import { Row, Tag } from "antd";
import { Link } from "react-router-dom";
import CustomTable from "../../../../components/forms/CustomTable";
import { PRODUCT } from "../folder-section";

// import ConfirmModal from "../../modals/ConfirmModal";
// import SuccessModal from "../../modals/SuccessModal";

function DirectoryTable({ dataSource, parentDirectory, type }) {
  const columns = [
    {
      title: "SR NO",
      dataIndex: "index",
      width: "20px",
      editable: false,
    },

    {
      title: "FOLDER NAME",
      dataIndex: "name",
      width: "40px",
      render: (text, row) => {
        return parentDirectory ? (
          <Link to={`/sub-directory/${row.id}`}>{text}</Link>
        ) : type == PRODUCT ? (
          <Link to={`/product-standard/${row.id}`}>{text}</Link>
        ) : (
          <Link to={`/directory/${row.id}`}>{text}</Link>
        );
      },
    },
    
    {
      title: "Created AT",
      dataIndex: "created_at",
      width: "40px",
    },

  
    // {
    //   title: "ENTITY ADMIN",
    //   dataIndex: "entity_admin",
    //   key: "entity_admin",
    //   width: "40px",
    //   render: (users) => (
    //     <>
    //       {users?.map((user) => {
    //         return <Tag key={user}>{user.toUpperCase()}</Tag>;
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "actions",
      dataIndex: "actions",
      width: "40px",
    },
  ];

  const columnsProduct = [
    {
      title: "SR NO",
      dataIndex: "index",
      width: "20px",
      editable: false,
    },

    {
      title: "FOLDER NAME",
      dataIndex: "name",
      width: "40px",
      render: (text, row) => {
        return parentDirectory ? (
          <Link to={`/sub-directory/${row.id}`}>{text}</Link>
        ) : type == PRODUCT ? (
          <Link to={`/product-standard/${row.id}`}>{text}</Link>
        ) : (
          <Link to={`/directory/${row.id}`}>{text}</Link>
        );
      },
    },
    {
      title: "Periodic Review Date",
      dataIndex: "review_date",
      width: "40px",
    },
    {
      title: "Created AT",
      dataIndex: "created_at",
      width: "40px",
    },

    // {
    //   title: "ENTITY ADMIN",
    //   dataIndex: "entity_admin",
    //   key: "entity_admin",
    //   width: "40px",
    //   render: (users) => (
    //     <>
    //       {users?.map((user) => {
    //         return <Tag key={user}>{user.toUpperCase()}</Tag>;
    //       })}
    //     </>
    //   ),
    // },
    {
      title: "actions",
      dataIndex: "actions",
      width: "40px",
    },
  ];

  return (
    <div>
      <Row gutter={[0, 5]} justify="end"></Row>
      <CustomTable columns={type == PRODUCT?columnsProduct:columns} dataSource={dataSource}/>
    </div>
  );
}

export default DirectoryTable;
