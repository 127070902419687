import React from "react";

import { DatePicker, Input } from "antd";

const InputFeild = ({
  label,
  field,
  placeholder,
  value,
  onChange,
  disabled,
  type = "text",
}) => {
  return (
    <div style={{ marginTop: "20px" }}>
      <label> {label} </label> <br />
      {type === "text" ? (
        <Input
          disabled={disabled}
          value={value}
          onChange={onChange}
          name={field}
          placeholder={placeholder}
          className="underLine"
          type={type}
        />
      ) : (
        <DatePicker
          disabled={disabled}
          value={value}
          // onChange={onChange}
          name={field}
          placeholder={placeholder}
          className="underLine"
          onChange={(date, dateString) => onChange(date.toDate())}
        />
      )}
    </div>
  );
};

export default InputFeild;
