import { FunctionComponent, MouseEventHandler } from "react";
import { Button } from 'primereact/button';
import { primaryColor, secoundryColor } from "../const";

interface CustomButtonProps {
    lable: string,
    onClick: MouseEventHandler
    isFull?: boolean,
    isSecoundry?: boolean
}

export const CustomButton: FunctionComponent<CustomButtonProps> = ({ lable, onClick, isFull, isSecoundry }) => {
    return (<Button style={{
        background: isSecoundry ? secoundryColor : primaryColor, width: isFull ? '100%' : '', marginLeft: '10px',
        border: `1px solid ${isSecoundry ? secoundryColor : primaryColor}`
    }} label={lable} onClick={onClick} />);
}

export const CustomOutlineButton: FunctionComponent<CustomButtonProps> = ({ lable, onClick, isFull, isSecoundry }) => {
    return (<Button style={{ width: isFull ? '100%' : '', marginLeft: '10px' }} label={lable} onClick={onClick} className="p-button-outlined p-button-secondary" />);
}

export const CustomIconButton: FunctionComponent<any> = ({ onClick, icon }) => {
    return (<Button style={{ marginLeft: '10px' }} onClick={onClick} className="p-button-rounded p-button-text" >
        <img src={process.env.PUBLIC_URL + '/icons/' + icon} alt="logo" style={{ height: '20px' }} />

    </Button>);
}
