import React, { useState, useEffect } from "react";
import { Modal, notification, Row, Col, Button } from "antd";
import { RiCloseFill, RiErrorWarningFill } from "react-icons/ri";
import { putCall } from "../../../../../../services/api_service";
import GenerateForm from "../../../../../../components/forms/GenerateForm";
import { PRODUCT } from "../../../folder-section";


function EditDir({
  showModel,
  setShowModel,
  selected,
  title,
  getDir,
  parentDirectory, 
  type
}) {
  const [loading, setloading] = useState();
  const [formData, setFormData] = useState({ ...selected });
  useEffect(() => {
    setFormData({ ...selected });
  }, [selected]);

  const onSubmit = async () => {
    const { name } = formData;

    if (name !== "") {
      setloading(true);
      try {
        if (parentDirectory) {
          const addDir = await putCall(`/directory/sub-directory`, {
            ...formData,
            parentDirectory,
          });
          if (addDir && addDir.success) {
            notification.open({
              message: "Data Updated Successfully",
              description: "",
              icon: <RiErrorWarningFill style={{ color: "#00BA75" }} />,
              closeIcon: (
                <RiCloseFill
                  className="remix-icon da-text-color-black-80"
                  size={24}
                />
              ),
            });
          }

          getDir(parentDirectory);
          setloading(false);
          setShowModel(false);
        } else {
          const addDir = await putCall(`/directory`, {
            ...formData,
          });
          if (addDir && addDir.success) {
            notification.open({
              message: "Data Updated Successfully",
              description: "",
              icon: <RiErrorWarningFill style={{ color: "#00BA75" }} />,
              closeIcon: (
                <RiCloseFill
                  className="remix-icon da-text-color-black-80"
                  size={24}
                />
              ),
            });
          }

          getDir();
          setloading(false);
          setShowModel(false);
        }
      } catch (e) {
        let errorMsg = "something went wrong.";

        if (e.response) {
          if (e.response?.message) {
            errorMsg = e?.response?.message;
          } else if (e?.response?.data?.message) {
            errorMsg = e?.response?.data?.message;
            console.log(errorMsg);
          }
        }
        notification.open({
          message: "Error",
          description: e?.response?.data?.message,
          icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
          closeIcon: (
            <RiCloseFill
              className="remix-icon da-text-color-black-80"
              size={24}
            />
          ),
        });

        setloading(false);

        // handleModleClose();
      }
    } else {
      notification.open({
        message: "Error",
        description: "All fields are mandatory",
        icon: <RiErrorWarningFill style={{ color: "#FF0022" }} />,
        closeIcon: (
          <RiCloseFill
            className="remix-icon da-text-color-black-80"
            size={24}
          />
        ),
      });
    }
  };

  return (
    <div>
      {selected && (
        <Modal
          centered
          title={title}
          visible={showModel}
          onOk={() => setShowModel(false)}
          onCancel={() => setShowModel(false)}
          width={1000}
          footer={[
            <Button
              key="submit"
              type="secoundry"
              loading={loading}
              onClick={onSubmit}
            >
              Update
            </Button>,
          ]}
        >
          <Row gutter={15}>
            <Col span={24}>
              <GenerateForm
                formField={type == PRODUCT ? formFieldProduct : formField}
                formData={formData}
                setFormData={setFormData}
                colSpan={8}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </div>
  );
}

export default EditDir;

const formField = [
  {
    label: "Directory name",
    placeholder: "Enter Directory name",
    field: "name",
    inputType: "text",
  },
];

const formFieldProduct = [
  {
    label: "Product Standard name",
    placeholder: "Enter Product Standard name",
    field: "name",
    inputType: "text",
  },
  {
    label: "Periodic Review Date",
    placeholder: "Enter Directory name",
    field: "review_date",
    inputType: "date",
  },
];
