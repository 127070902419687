import axios from "axios";
import { toast } from "react-toastify";
require("dotenv").config();

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "https://bbbe-backend.esgfit.in";
// const BASE_URL = "http://18.138.217.143:3002";
// const BASE_URL = "https://dhercorporate.herokuapp.com";
//const BASE_URL = "https://testdjango.demoapps.ind.in";
// const BASE_URL = "http://ec2-18-138-217-143.ap-southeast-1.compute.amazonaws.com:3002";
export const getCall = async (path: string): Promise<any> => {
  try {    
    const response = await axios.get(BASE_URL + path);
    const resData = response.data;
    return resData;
  } catch (errors) {
    console.error(errors);
    toast.error("Someting went wrong");
    return [];
  }
};

export const postCall = async (
  path: string,
  body: any,
  config: any = null
): Promise<any> => {
  try {
    const response = await axios.post(BASE_URL + path, body, config);

    const resData = response.data;

    return resData;
  } catch (errors) {
    console.error(errors);
    toast.error("Someting went wrong ." + errors);

    return [];
  }
};

export const putCall = async (path: string, body: any): Promise<any> => {
  try {
    const response = await axios.put(BASE_URL + path, body);

    const resData = response.data;

    return resData;
  } catch (errors) {
    console.error(errors);
    toast("Someting went wrong" + errors);

    return [];
  }
};

export const deleteCall = async (path: string): Promise<any> => {
  try {
    const response = await axios.delete(BASE_URL + path);

    const resData = response.data;

    return resData;
  } catch (errors) {
    console.error(errors);
    toast("Someting went wrong" + errors);

    return [];
  }
};
