import { FunctionComponent, useState } from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

import { deleteCall } from "../../../../services/api_service";
import { CustomIconButton } from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";

interface ElementTableProps {
  setShowCreateElement: Function;
  setselectedEditElement: Function;
  getElements: Function;
  elementList: Array<any>;
  selectedModule: any;
  selectedSubModule: any;
  selectedPs: any;
  moduleList: Array<any>;
  subModuleList: Array<any>;
  handleModuleChange: Function;
  handleSubModuleChange: Function;
  handlePSChange: Function;
  psList: Array<any>;
  setSelectedViewElement: any;
  handleStatusChange: Function;
  selectedStatus: any;
}

const currentUserRole = localStorage.getItem("userRole") || "";

const ElementTable: FunctionComponent<ElementTableProps> = ({
  setselectedEditElement,
  getElements,
  elementList,
  selectedModule,
  selectedSubModule,
  selectedPs,
  moduleList,
  subModuleList,
  handleModuleChange,
  handleSubModuleChange,
  handlePSChange,
  psList,
  setSelectedViewElement,
  handleStatusChange,
  selectedStatus,
}) => {
  console.log(selectedStatus);
  const getFilteredElementsList = () => {
    if (selectedStatus.id === 1) {
      return elementList.filter((element) => !element.risk && !element.status);
    }
    if (selectedStatus.id === 2) {
      return elementList.filter((element) => element.risk && element.status);
    }

    return elementList;
  };
  const deleteElemet = (id: string) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      deleteCall("/element/" + id).then(() => {
        getElements();
      });
    }
  };

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(50);

  const onCustomPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <CustomIconButton
          onClick={() => {
            setSelectedViewElement(rowData);
          }}
          icon="detail.svg"
        ></CustomIconButton>
        {currentUserRole != 'L1' && <CustomIconButton
          onClick={() => {
            setselectedEditElement(rowData);
          }}
          icon="users_green.svg"
        ></CustomIconButton>}
        <CustomIconButton
          onClick={() => {
            deleteElemet(rowData.id);
          }}
          icon="delete.svg"
        ></CustomIconButton>
      </div>
      // <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" onClick={() => setselectedEditPs(rowData)} />
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      <div>
        {rowData.status && rowData.risk ? (
          <span className="tag is-success">Completed</span>
        ) : (
          <span className="tag is-danger">Pending</span>
        )}
      </div>
    );
  };

  const template: any = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 50, value: 50 },
        { label: 100, value: 100 },
        { label: 200, value: 200 },
      ];

      return (
        <>
          <span
            className="p-mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <div style={{ width: "100px" }}>
            <Dropdown
              value={options.value}
              options={dropdownOptions}
              onChange={options.onChange}
              appendTo={document.body}
            />
          </div>
        </>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  return (
    <div>
      <div className="columns">
        <div className="column is-3 ">
          <CustomSelect
            label="Module Name"
            placeholder="Select Module"
            selectedOp={selectedModule}
            handleOpChange={handleModuleChange}
            opList={moduleList}
          ></CustomSelect>
        </div>
        <div className="column is-3">
          <CustomSelect
            label="Sub-Module Name"
            placeholder="Select Sub-Module"
            selectedOp={selectedSubModule}
            handleOpChange={handleSubModuleChange}
            opList={subModuleList}
            hasAllOp={subModuleList.length === 0}
          ></CustomSelect>
        </div>
        <div className="column is-4">
          <CustomSelect
            label="Performance Standard Name"
            placeholder="Select Performance Standard"
            selectedOp={selectedPs}
            handleOpChange={handlePSChange}
            opList={psList}
            hasAllOp={true}
          ></CustomSelect>
        </div>
        <div className="column is-2">
          <CustomSelect
            label="Status"
            placeholder="Select Status"
            selectedOp={selectedStatus}
            handleOpChange={handleStatusChange}
            opList={[
              { id: 1, name: "Pending" },
              { id: 2, name: "Completed" },
            ]}
            hasAllOp={true}
          ></CustomSelect>
        </div>
      </div>

      {getFilteredElementsList().length > 0 ? (
        <div style={{ width: "100%" }}>
          <DataTable
            value={getFilteredElementsList()}
            paginator
            paginatorTemplate={template}
            first={first}
            rows={rows}
            onPage={onCustomPage}
            style={{ width: "100%" }}
            paginatorClassName="p-jc-end"
            className="p-mt-6"
          >
            <Column
              field="index"
              header="Sr No"
              style={{ width: "5rem" }}
            ></Column>
            <Column field="name" header="Element"></Column>
            <Column
              field="status"
              header="Status"
              style={{ width: "14rem" }}
              body={statusBodyTemplate}
            ></Column>
            <Column
              field="user"
              header="Assinged User"
              style={{ width: "14rem" }}
            ></Column>
            <Column
              header="Actions"
              body={actionBodyTemplate}
              style={{ textAlign: "center", width: "15em" }}
            ></Column>
          </DataTable>
        </div>
      ) : (
        <div className="has-text-centered section">
          <img
            src={process.env.PUBLIC_URL + "/icons/emptyTable.svg"}
            alt="logo"
          />{" "}
          <br />
          Please make a selection to view the search results
        </div>
      )}
    </div>
  );
};

export default ElementTable;
