import { FunctionComponent, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

import Editps from "./EditPS";
import { CustomIconButton } from "../../../../components/CustomButton";
import { Link } from "react-router-dom";
import { Toast } from "primereact/toast";
import { deleteCall } from "../../../../services/api_service";

interface PsTableProps {
  setShowCreatPs: Function;
  getps: Function;
  selectedSubModule: any;
  selectedModule: any;
  psList: Array<any>;
}

const PsTable: FunctionComponent<PsTableProps> = ({
  selectedModule,
  selectedSubModule,
  psList,
  getps,
}) => {
  const [selectedEditPs, setselectedEditPs] = useState();
  const toast = useRef<any>(null);

  const deletePs = (id: string) => {
    if (
      window.confirm(
        "Deleting this will delete all the related/past data. Are you sure you want to delete this?"
      )
    ) {
      deleteCall("/ps/" + id)
        .then((data) => {
          toast.current.show({
            severity: "success",
            summary: "",
            detail: "Deleted Successfully",
            life: 3000,
          });
          getps(
            selectedSubModule.id !== "all"
              ? selectedSubModule.id
              : selectedModule.id
          );
        })
        .catch((e) => {
          toast.current.show({
            severity: "error",
            summary: "",
            detail: `Record Not Deleted !`,
            life: 3000,
          });
        });
    }
  };

  const setLocalData = (psData: any) => {
    localStorage.setItem("moduleData", JSON.stringify(selectedModule));
    localStorage.setItem("subModuleData", JSON.stringify(selectedSubModule));
    localStorage.setItem("psData", JSON.stringify(psData));
  };

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const onCustomPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  const template: any = {
    layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
    RowsPerPageDropdown: (options: any) => {
      const dropdownOptions = [
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
      ];

      return (
        <>
          <span
            className="p-mx-1"
            style={{ color: "var(--text-color)", userSelect: "none" }}
          >
            Items per page:{" "}
          </span>
          <div style={{ width: "100px" }}>
            <Dropdown
              value={options.value}
              options={dropdownOptions}
              onChange={options.onChange}
              appendTo={document.body}
            />
          </div>
        </>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span
          style={{
            color: "var(--text-color)",
            userSelect: "none",
            width: "120px",
            textAlign: "center",
          }}
        >
          {options.first} - {options.last} of {options.totalRecords}
        </span>
      );
    },
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <div>
        <CustomIconButton
          onClick={() => {
            setselectedEditPs(rowData);
          }}
          icon="edit.svg"
        ></CustomIconButton>
        <CustomIconButton
          onClick={() => {
            deletePs(rowData.id);
          }}
          icon="delete.svg"
        ></CustomIconButton>
      </div>
      // <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" onClick={() => setselectedEditPs(rowData)} />
    );
  };

  const nameBodyTemplate = (rowData: any) => {
    return (
      <div>
        <Link to="/elements" onClick={() => setLocalData(rowData)}>
          {" "}
          {rowData.name}{" "}
        </Link>
      </div>
    );
  };

  return (
    <div>
      {psList.length > 0 ? (
        <div style={{ width: "100%" }}>
          <DataTable
            value={psList}
            paginator
            paginatorTemplate={template}
            first={first}
            rows={rows}
            onPage={onCustomPage}
            style={{ width: "100%" }}
            paginatorClassName="p-jc-end"
            className="p-mt-6"
          >
            <Column
              field="index"
              header={"Sr No"}
              style={{ width: "8em" }}
            ></Column>
            <Column
              field="name"
              header={"Performance Standards"}
              body={nameBodyTemplate}
            ></Column>
            <Column
              body={actionBodyTemplate}
              style={{ textAlign: "center", width: "12em" }}
            ></Column>
          </DataTable>
        </div>
      ) : (
        <div className="has-text-centered section">
          <img
            src={process.env.PUBLIC_URL + "/icons/emptyTable.svg"}
            alt="logo"
          />{" "}
          <br />
          Please make a selection to view the search results
        </div>
      )}

      {selectedEditPs ? (
        <Editps
          selectedps={selectedEditPs}
          setSelectedps={setselectedEditPs}
          getps={getps}
        >
          {" "}
        </Editps>
      ) : (
        ""
      )}

      <Toast ref={toast} />
    </div>
  );
};

export default PsTable;
