import { FunctionComponent } from "react";

import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from "primereact/multiselect";

interface CutomSelectProps {
    label?: string,
    placeholder: string,
    opList: any,
    handleOpChange: Function,
    selectedOp: string,
    hasAllOp?: boolean,

}

const CutomSelect: FunctionComponent<CutomSelectProps> = ({ selectedOp, handleOpChange, opList, label, placeholder, hasAllOp }) => {
    if (hasAllOp) {
        opList = [{ name: 'All', id: 'all' }, ...opList];
    }
    return (
        <div>
            {label ? <div> <label className="lable" > {label} </label> </div> : ''}
            <Dropdown value={selectedOp} options={opList} onChange={(e) => { handleOpChange(e.value) }} optionLabel='name' placeholder={placeholder} />
        </div>
    );
}

export default CutomSelect;