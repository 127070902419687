import { FunctionComponent, useEffect, useState } from "react";
import { getCall } from "../../../../services/api_service";
import "../stat.css";
import { Link } from "react-router-dom";
interface StatProps {}
const Stat: FunctionComponent<StatProps> = () => {
  const [stat, setstat] = useState<any>(null);
  const getStat = () => {
    getCall("/common/stat").then((data) => {
      console.log(data);
      setstat(data);
    });
  };
  useEffect(() => {
    getStat();
  }, []);
  return (
    <div className="container ">
      {stat ? (
        <div className="columns is-multiline">
          {localStorage.getItem("userRole") === "admin" && (
            <div className="columns is-multiline">
              <div className="column is-6">
                <Link to={"/module"} className="box">
                  <div className="columns">
                    <div className="column is-4">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/icons/" +
                          "module_green.svg"
                        }
                        alt=""
                        style={{ height: "60px" }}
                      />
                    </div>
                    <div className="column">
                      <p className=" is-size-4 has-text-weight-bold">
                        {stat.modulesCount}
                      </p>
                      <p className="label">Modules</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="column is-6">
                <Link to="/Performance-standards" className="box">
                  <div className="columns">
                    <div className="column is-4">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/icons/" + "ps_green.svg"
                        }
                        alt=""
                        style={{ height: "60px" }}
                      />
                    </div>
                    <div className="column">
                      <p className=" is-size-4 has-text-weight-bold">
                        {stat.psCount}
                      </p>
                      <p className="label">Performance Standards</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="column is-6">
                <Link to={"/elements"} className="box">
                  <div className="columns">
                    <div className="column is-4">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/icons/" +
                          "elements_green.svg"
                        }
                        alt=""
                        style={{ height: "60px" }}
                      />
                    </div>
                    <div className="column">
                      <p className=" is-size-4 has-text-weight-bold">
                        {stat.elementCount}
                      </p>
                      <p className="label">Elements</p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="column is-6">
                <Link to={"/users"} className="box">
                  <div className="columns">
                    <div className="column is-4">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/icons/" + "users_green.svg"
                        }
                        alt=""
                        style={{ height: "60px" }}
                      />
                    </div>
                    <div className="column">
                      <p className=" is-size-4 has-text-weight-bold">
                        {stat.usersCount}
                      </p>
                      <p className="label">Users</p>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          )}
          <div className="column">
            <div className="box">
              <table className="table-css table-space">
                <tr>
                  <td>
                    <div className="boxes well-managed"></div>
                  </td>
                  <td>
                    <div>Well-Managed</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="boxes satisfactory"></div>
                  </td>
                  <td>
                    <div>Satisfactory</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="boxes some-improve-opp"></div>
                  </td>
                  <td>
                    <div>Some Improvement Opportunity</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="boxes major-improve-opp"></div>
                  </td>
                  <td>
                    <div>Major Improvement Opportunity</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="boxes unacceptable-risk-exp"></div>
                  </td>
                  <td>
                    <div>Unacceptable Risk Exposure</div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default Stat;
