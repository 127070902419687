import { FunctionComponent, useEffect, useState } from "react";
import { getCall, postCall } from "../../../../services/api_service";
import ExcelToJson from "./ExcelToJson";
import CutomInput from "../../../../components/CustomInput";
import { CustomButton } from "../../../../components/CustomButton";
import CustomSelect from "../../../../components/CustomSelect";
import { roleList } from "../../../../utils/utils";

interface CreateModuleModelProps {
  getUsers: Function;
  setShowCreateUser: Function;
}

const CreateModuleModel: FunctionComponent<CreateModuleModelProps> = ({
  getUsers,
  setShowCreateUser,
}) => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [role, setrole] = useState<any>("");
  const [xlData, setxlData] = useState<Array<any>>([]);
  const [entity, setEntity] = useState<any>("");
  const [unit, setUnit] = useState<any>("");

  const [entityList, setEntityList] = useState([]);
  const [unitList, setUnitsList] = useState([]);

  const userRole = localStorage.getItem("userRole");

  // const roleList = [
  //   { id: "L1", name: "L1" },
  //   { id: "L2", name: "L2" },
  //   { id: "L2", name: "L2" },
  //   { id: "Auditor", name: "Auditor" },
  // ];
  const getEntities = () => {
    getCall("/users/entities").then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      setEntityList(data);
    });
  };
  const getUnits = (entity_id: string) => {
    getCall("/users/units/" + entity_id).then((data) => {
      data = data.map((el: any, index: number) => {
        return { index: index + 1, ...el };
      });
      setUnitsList(data);
    });
  };
  const addUser = () => {
    if (xlData.length > 0) {
      postCall("/users/addBulk", { users: xlData }).then(() => {
        getUsers();
        setShowCreateUser(false);
      });
    } else if (name !== "") {
      postCall("/users", {
        name,
        useremail:email,
        role: role.name,
        entity_id: entity.id,
        unit_id: unit.id,
      }).then(() => {
        getUsers();
        setShowCreateUser(false);
      });
    }
  };

  useEffect(() => {
    getEntities();
  }, []);

  useEffect(() => {
    console.log("entity", entity);
    
    if (entity) {
      getUnits(entity.id);
    }
  }, [entity]);

  return (
    <div>
      <div className="modal is-active ">
        <div className="modal-background"></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <div>
              <button
                className="delete"
                aria-label="close"
                onClick={() => {
                  setShowCreateUser(null);
                }}
                style={{ float: "right" }}
              ></button>
              <p className="modal-card-title">Add Users</p>
            </div>
            <br />
            <div className="columns is-multiline">
              <div className="column is-6">
                <CutomInput
                  label="Name"
                  onChange={($event) => {
                    setname($event.target.value);
                  }}
                  value={name}
                ></CutomInput>
              </div>
              <div className="column is-6">
                <CutomInput
                  label="Email"
                  onChange={($event) => {
                    setemail($event.target.value);
                  }}
                  value={email}
                ></CutomInput>
              </div>
              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    setrole(value);
                  }}
                  opList={roleList}
                  placeholder="Select Role"
                  selectedOp={role}
                ></CustomSelect>
              </div>
              {userRole == "admin" && (
                <div className="column is-6">
                  <CustomSelect
                    handleOpChange={(value: any) => {
                      setEntity(value);
                    }}
                    opList={entityList}
                    placeholder="Select Entity"
                    selectedOp={entity}
                  ></CustomSelect>
                </div>
              )}
              <div className="column is-6">
                <CustomSelect
                  handleOpChange={(value: any) => {
                    setUnit(value);
                  }}
                  opList={unitList}
                  placeholder="Select Unit"
                  selectedOp={unit}
                ></CustomSelect>
              </div>
            </div>
            <p className="has-text-centered">or Add Bulk for Excel</p> <br />
            <ExcelToJson setxlData={setxlData}></ExcelToJson> <br />
            <div className="has-text-centered">
              <CustomButton lable="Add" onClick={addUser}></CustomButton>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CreateModuleModel;
