import * as XLSX from "xlsx";

export const readFile = async (file: File, header: Array<string>) => {
  let f = file;

  return await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (evt: any) => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws);
      console.log("needed Header: " + header);

      console.log(data);

      /* Update state */
      resolve(data);
      // resolve(convertToJson(data, header)); // shows data in json format
    };
    reader.readAsBinaryString(f);
  });
};

// const convertToJson = (csv: any, headers: Array<string>) => {
//   console.log(csv);

//   let lines = csv.split("\n");
//   console.log(lines);

//   let result = [];

//   for (let i = 1; i < lines.length; i++) {
//     let obj: any = {};
//     let currentline = lines[i].split(",");
//     for (let j = 0; j < headers.length; j++) {
//       obj[headers[j]] = currentline[j];
//     }

//     result.push(obj);
//   }
//   //   console.log(result);

//   //return result; //JavaScript object
//   return result; //JSON
// };
